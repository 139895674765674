html{
  font-size: 62.5%;
}

.solutionpage {
  width: 100%;
  height: auto;
}

.desktop_view_offering_cards .container{
  
}

.solutionpage_heading {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3.5rem;
}

.solutionpage_heading h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 7.5rem;
  text-align: center;
  text-transform: capitalize;
  color: #5462ba;
}

.solutionpage_heading p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.7rem;
  text-align: center;
  color: #707070;
}

.carded_solution {
  width: 28rem;
  height: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  border: 2px solid #5462ba;
  padding: 2.5rem;
  transition: all 0.5s linear;
  cursor: pointer;
}

.carded_solution figure {
  /* width: 150px; */
  /* background: black; */
}

/* for active */
.carded_solution_first, .carded_solution_second, .carded_solution_third{
  border: 2px solid #5462ba;
  transform: scale(1.03);
  cursor: pointer;
  background: #5462ba;
  color: white !important;
  width: 28rem;
  height: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  border: 2px solid #5462ba;
  padding: 2.5rem;
  transition: all 0.5s linear;
}

.carded_solution_first img{
  align-items: center;
  filter: brightness(0) invert(1);

}

.carded_solution_second img{
  align-items: center;
  filter: brightness(0) invert(1);

}

.carded_solution_third img{
  align-items: center !important;
  filter: brightness(0) invert(1);

}

.carded_solution_first  h2 {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 5rem;

  color: white;
}

.carded_solution_second  h2 {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 5rem;

  color: white;
}

.carded_solution_third  h2 {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 5rem;

  color: white;
}

.carded_solution_first p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: white;
}

.carded_solution_second p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: white;
}

.carded_solution_third p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: white;
}

.carded_solution_first, .carded_solution_second, .carded_solution_third img {
  /* filter: brightness(0) invert(1); */
  color: white;
}
.carded_solution_third figure {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carded_solution_first figure {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carded_solution_second figure {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* end for active */


.solutionpage .carded_solution:hover {
  border: 2px solid #5462ba;
  transform: scale(1.03);
  cursor: pointer;
  background: #5462ba;
  color: white !important;
}

.carded_solution:hover h2 {
  color: white;
}

.carded_solution:hover p {
  color: white;
}

.carded_solution:hover img {
  filter: brightness(0) invert(1);
  color: white;
}

.carded_solution figure {
  /* width: 150px; */
  /* background: rebeccapurple; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carded_solution h2 {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 5rem;

  color: #070a1d;
}

.carded_solution p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: #707070;
}

.carded_solution_third figure {
  align-items: center;
  width: 16rem !important;
  /* background: green; */
}

.carded_solution_first figure {
  width: 16rem !important;
  align-items: center;
}

.carded_solution_second img {
  /* filter: invert(58%) sepia(9%) saturate(3339%) hue-rotate(195deg)
    brightness(94%) contrast(93%); */
  width: 9rem;
}

.carded_solution_second figure img {
  width: 13rem;
}
.carded_solution_third figure img {
  width: 13rem;
}
.carded_solution_first figure img {
  width: 11rem;
}
  .solutionpage .rows{
    gap: 5rem !important;
  }
/* css services and cards section end */


/* median queru */

@media screen and (max-width: 1024px) {
  .solutionpage .rows{
    width: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    gap: 6rem !important;
  }

  .solutionpage .rows::-webkit-scrollbar{
    overflow: hidden;
  }
}