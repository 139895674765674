html {
  font-size: 62.5% !important;
}

.blog_detail_page {
  width: 100%;
  /* height: 100vh; */
  /* background: red; */
  margin-top: 8rem;
}
.blog_detail_page .container {
  width: 85%;
  margin: 2rem auto;
}
.blog_detail_page .blog_detail_heading {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blog_detail_heading h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
  /* identical to box height, or 180% */

  color: #5462ba;
}
.blog_detail_heading h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 3.3rem;
  text-align: center;
  text-transform: capitalize;
  word-spacing: 1px;
  color: #3f3f3f;
}

.blog_detail_heading p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 3rem;
  margin-top: 0.7rem;
  text-align: center;
  text-transform: capitalize;

  color: #3f3f3f;
}
.blog_detail_img figure {
  width: 100%;
  height: 46rem;
}
.blog_detail_img img {
  width: 100%;
  height: 100%;
  background: 100%;
  background-repeat: no-repeat;
}

/* profile ssection */
.blog_detail_profile_data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* background: red; */
}

.blog_profile_img {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rebeccapurple; */
}

.blog_profile_img img{
  width: 5rem !important;
  border-radius: 50%;
}

.profile_data {
  /* background: royalblue; */
  margin-left: 1rem !important;
}
.profile_data h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.5rem;
  /* identical to box height, or 161% */

  color: #070a1d;
}
.profile_data p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #565656;
}

.blog_detail_profile_right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_detail_profile_right .blog_links {
  padding: 1rem;
  border: 1px solid #565656;
  margin: 5px 1rem;
  border-radius: 1rem;
  background: #ffffff;
  border: 1px solid #d0d0d0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.blog_copy_link a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem !important;

  color: #7b7b7b;
}

.blog_copy_link {
  padding: 0.8rem;
  background: #ffffff;
  border: 1px solid #d0d0d0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.blog_copy_link img {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.blog_links a {
  height: 1.5rem;
  width: 1.5rem !important;
}
.blog_links img {
}
.blog_detail_profile_right img {
  width: 100%;
}

.blog_links .facebook {
  width: 0.5rem !important;
}
.blog_copy_link .link {
  width: 1rem !important;
}
/* end of profile section */

/* **************************hyperlinks section*********************** */
.blog_details_hyperlinks {
  width: 100%;
  height: auto;
  /* background: #5462ba; */
  margin-top: 5%;
}

.blog_details_hyperlinks .container {
  width: 85%;
  margin: 3rem auto;
}

.blog_details_hyperlinks p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.3rem;

  color: #4d4d4d;
}

.blog_details_left_hyperlinks input {
  background: #ffffff;
  border: 1px solid #a7a7a7;
  border-radius: 4px;
  width: 25rem;
  height: 4rem;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0.4rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  color: #aeaeae;
  padding: 1.5rem;
}

.blog_details_left_hyperlinks .input_img {
  background: url("../Images/Shape.png") no-repeat left;
  /* background-size: 100% 10%; */
  display: flex;
  padding-left: 2rem;
}

.blog_details_content p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 4rem;
  /* identical to box height */
  color: #7d7d7d;
  margin-top: 1rem;
}
.blog_details_content li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  margin-left: 1rem;
  line-height: 2.5rem;
  /* identical to box height */
  /* padding: 5px; */
  color: #7d7d7d;
  padding: 0.5rem;
}

.subscribe_div {
  margin-top: 3.5rem;
}

.subscribe_div h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 3.5rem;
  /* identical to box height */

  color: #3d3d3d;
}
.subscribe_div button {
  width: 25rem;
  height: 4rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 3rem;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  background: #202e86;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
  margin-top: 2rem;
}

:target {
  color: #070a1d !important;
  /* background: #7b7b7b; */
  font-weight: 500 !important;
  position: relative;
}
:target::before {
  content: "";
  position: absolute;
  top: 0px;
  left: -1rem;
  width: 0.5rem;
  border-radius: 1rem;
  margin-right: 2rem !important;
  height: 11.5rem;
  color: #070a1d;
  background: #070a1d;
  /* border: 2px solid #070a1d; */
}
/* **************************hyperlinks section end */

/* *************************related conetent section ****************/

.blog_detail_related {
  width: 100%;
  height: auto;
  /* background: red; */
}
.blog_detail_related .container {
  width: 85%;
  margin: 3rem auto;
}
.related_heading h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 3.5rem;
  /* identical to box height, or 129% */

  color: #070a1d;
}
.related_heading p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  /* identical to box height */

  color: #4d4d4d;
}
.veiw_all {
  width: fit-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
  text-transform: uppercase;
  color: #5ea410;
  margin-bottom: 2%;
}
.blog_related_img_section {
  width: 100%;
  height: auto;
  /* background-color: rebeccapurple; */
}

.blog_detail_img_cards img {
  width: 100%;
}
.blog_detail_img_cards h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2rem;
  /* identical to box height */

  color: #5462ba;
}

.blog_detail_img_cards .blog_detail_cards_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  margin: 0.6rem 0px;
  word-spacing: 1px;
  line-height: 2rem;
  color: #070a1d;
}
.blog_detail_img_cards p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2rem;

  color: #7d7d7d;
}

.blog_related_img_section .container {
  width: 80%;
}

/* ***************************end related content section****************** */


@media screen and (max-width: 1300px) {
  html{
    font-size: 59% !important;
  }
}

@media screen and (max-width: 1250px) {
  html{
    font-size: 57% !important;
  }
}

@media screen and (max-width: 1200px) {
  html{
    font-size: 55% !important;
  }
}

@media screen and (max-width: 1150px) {
  html{
    font-size: 53% !important;
  }
}

@media screen and (max-width: 1100px) {
  html{
    font-size: 51% !important;
  }
}

@media screen and (max-width: 1050px) {
  html{
    font-size: 49% !important;
  }
}


@media screen and (max-width: 1024px) {
  html {
    font-size: 71% !important;
  }

  .margin_left_portfolio_cards {
    margin-left: 220%;
  }

  .blog_profile_img img{
  width: 5rem !important;
  border-radius: 50%;
}
  

  .blog_detail_page {
    width: 100%;
    margin-top: 8rem;
  }
  .blog_detail_page .copy_link {
    display: none;
  }
  .blog_detail_page .container {
    width: 90%;
    margin: 2rem auto;
  }
  .blog_detail_page .blog_detail_heading {
    width: 80%;
  }

  .blog_detail_img figure {
    width: 100%;
    height: auto;
  }
  .blog_detail_img img {
    width: 100%;
    height: 100%;
    background: 100%;
    background-repeat: no-repeat;
  }

  /* link section */
  .mobile_none {
    display: none !important;
  }
  .blog_related_img_section .rows {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 5rem;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .blog_related_img_section .rows::-webkit-scrollbar {
    overflow: hidden;
  }

  .blog_related_img_section .blog_detail_cards_mobile {
    width: 100%;
    /* display: flex !important; */
  }
  .veiw_all {
    /* width: 20%; */
    margin-bottom: 8%;
  }
  .related_heading p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    /* identical to box height */

    color: #4d4d4d;
  }
}

@media screen and (max-width: 1024px) {
  .margin_left_portfolio_cards {
    margin-left: 220%;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 54% !important;
  }
  .blog_detail_page .blog_detail_heading {
    width: 90%;
  }



  .margin_left_portfolio_cards {
    margin-left: 220%;
  }

  .blog_detail_page {
    width: 100%;
    margin-top: 8rem;
  }
  .blog_detail_page .copy_link {
    display: none;
  }
  .blog_detail_page .container {
    width: 90%;
    margin: 2rem auto;
  }
  .blog_detail_page .blog_detail_heading {
    width: 80%;
  }

  .blog_detail_img figure {
    width: 100%;
    height: auto;
  }
  .blog_detail_img img {
    width: 100%;
    height: 100%;
    background: 100%;
    background-repeat: no-repeat;
  }

  /* link section */
  .mobile_none {
    display: none !important;
  }
  .blog_related_img_section .rows {
    width: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    gap: 2rem;
  }
  .blog_related_img_section .rows::-webkit-scrollbar {
    overflow: hidden;
  }

  .blog_related_img_section .blog_detail_cards_mobile {
    width: 100%;
    /* display: flex !important; */
  }
  .veiw_all {
    width: auto !important;
    margin-bottom: 4%;
  }
}
/* 576 */

@media screen and (max-width: 576px) {
  html {
    font-size: 46% !important;
  }

  .profile_data {
    /* background: royalblue; */
    margin-left: 1rem !important;
  }

  .blog_detail_page .blog_detail_heading {
    width: 90%;
  }
  .veiw_all {
    width: 40%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: #5ea410;
    margin-bottom: 9%;
  }
  .related_heading p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.7rem;
    /* identical to box height */

    color: #4d4d4d;
  }
}

@media screen and (max-width: 492px) {
  html {
    font-size: 37.5% !important;
  }
  .blog_detail_page .blog_detail_heading {
    width: 90%;
  }
  .related_heading p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    /* identical to box height */

    color: #4d4d4d;
  }

  .profile_data {
    /* background: royalblue; */
    margin-left: 1rem !important;
  }
}

@media screen and (max-width: 380px) {
  html {
    font-size: 32.5% !important;
  }

  .profile_data {
    /* background: royalblue; */
    margin-left: 1rem !important;
  }
}
@media screen and (min-width: 1500px) {
  html {
    font-size: 75% !important;
  }
  .profile_data {
  /* background: royalblue; */
  margin-left: 1rem !important;
}
}

@media screen and (min-width: 1650px) {
  html {
    font-size: 81% !important;
  }
}
@media screen and (min-width: 1850px) {
  html {
    font-size: 85% !important;
  }
}

@media screen and (min-width: 2050px) {
  html {
    font-size: 90% !important;
  }
}

.swiper-slide element.style {
  margin-right: 8px;
  margin-left: 8rem !important;
}
