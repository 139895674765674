html {
  font-size: 62.5%;
}

.navbar {
  width: 100%;
  height: 6rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  gap: 35% !important;
  left: 0;
  top: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
}

.navStyle{
  color: white;
  text-align: center !important;
  align-items: center;
  color: #3048e1;
  transform: translateY(-2rem);
  border-bottom: 1px solid #3048e1;
}

.input_field {
  width: 100%;
  background: white !important;
  display: none;
  height: 4rem;
  top: 8rem;
  z-index: 0;
  /* left: 8rem; */
  position: absolute;
}
.input_field input {
  position: absolute;
  z-index: 1;
  left: 17%;
  width: 70%;
  height: 6rem;
  background: #fff;
  border-radius: 1rem;
  box-shadow: -0.5px 0.5px 0px rgba(0, 0, 0, 0.5);
}
.input_field .icon {
  padding-left: 3rem;
  padding-right: 2.5rem !important;
  background-image: url(../Images/Shape.png) no-repeat right !important;
  background-size: 2rem;
  z-index: 998;
  opacity: 0.9;
}

.logo_img {
  width: 22.5rem;
  /* background: rebeccapurple; */
}
.menu {
  display: flex;
  gap: 2rem;
  /* background-color: rebeccapurple; */
}

.search figure {
  width: 2.5rem;
  height: 2.5rem;
  background: #202e86;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.menu li {
  transition: all 0.5s linear;
  list-style: none;
  letter-spacing: 0.2rem;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  font-family: "poppins";
  line-height: 2.4rem;
  text-transform: capitalize;
}
.menu li:hover {
  cursor: pointer;
  color: black;
  transition: all 0.5s linear;
}

.menu li:active{
  border-bottom: none !important;
}

.navbar__icon {
  font-size: 2.5rem;
  cursor: pointer;
  display: none;
}

.navbar .mobile_view {
  width: 100%;
  border-radius: 1rem;
  height: 4rem;
  display: none;
}


.drop_down_menu {
  display: none;
  width: 22rem;
  user-select: none !important;

}
li:hover .drop_down_menu {
  display: block;
  background: white;
  position: absolute;
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  gap: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s linear;
}

@media (max-width: 1024px) {
  .navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 8rem;
    position: fixed;
    gap: 30% !important;
    left: 0;
    top: 0;
    width: 100%;
    background: #fff;
    box-shadow: none !important;
  z-index: 999;
  }


  .navbar__icon {
    display: flex;
  }

  .navStyle{
  color: white;
  /* padding: .7rem  1rem; */
  /* border-radius: 1rem; */
  text-align: center !important;
  align-items: center;
  border-bottom: 1px solid #3048e1;
  color: #3048e1;
  transform: translateY(1rem);
}

.down_arrow{
  float: right !important;
  padding-right: 11% !important;
}


.drop_down_menu {
  display: none;
  width: 80vw;
  margin-top: 2rem;
  align-items: start !important;

}


  .input_field .icon {
    padding-left: 3rem;
    padding-right: 2.5rem !important;
    background-image: url(../Images/Shape.png) no-repeat right !important;
    background-size: 2rem;
    z-index: 9999;
    opacity: 0.9;
  }
  .navbar .mobile_view {
    display: block;
  }
  .input_field {
    display: block !important;
  }
  .menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 100vh;
    left: 0;
    background: white;
    height: 100vh;
    width: 100vw;
    gap: 6rem !important;
    padding-left: 11% !important;
    padding-top: 4rem !important;
    /* text-align: center; */
    transition: all 0.5s linear;
  }
  .menu.active {
    position: absolute;
    top: 100%;
    font-size: 3rem !important;
    transition: all 0.5s linear;
  }

  .navbar .mobile_view {
    width: 100%;
    border-radius: 1rem;
    height: 7rem;
    display: block;
  }

  .menu li {
    transition: all 0.5s linear;
    list-style: none;
    font-size: 2.1rem;
    font-style: normal;
    font-weight: 500;
    font-family: "poppins";
    text-transform: capitalize;
    /* border-bottom: 0.1px solid black; */
    /* display: block; */
    /* width: 100%; */
  }

  .search figure {
    display: none;
  }
}
