html {
  font-size: 62.5%;
}
.name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.5rem;
  text-align: center;
  color: #000000;
}

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  color: #5462ba;
}

.team_images {
  width: 20rem;
  height: 17.5rem;
}
.team_images img {
  width: 100%;
  height: auto;
}


/* media query for team section */
@media screen and (max-width: 1024px) {

  .team {
    width: 100%;
    height: auto;
  }
  .team_images {
    width: 25rem;
  }
  .team_images img {
    width: 100%;
    height: auto;
  }


  .mobile_view_team_section{
    width: 70vw !important;
  }

  .team_img_mobile:nth-child(odd) {
    display: flex;
    width: 100% !important;
    /* margin-left: 10rem; */
    justify-content: flex-start;
  }

  .team_img_mobile:nth-child(even) {
    display: flex;
    width: 100% !important;
    /* margin-left: 10rem; */
    justify-content: flex-end;
  }



  .team .flex {
    width: 100%;
    height: auto;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 17rem;
    justify-content: center;
  }

  .name_position {
    margin-top: 22rem;
    width: 25rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    color: #000000;
  }
  .team_down_section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .team {
    width: 100%;
    height: auto;
  }
  .team_images {
    width: 25rem;
  }
  .team_images img {
    width: 100%;
    height: auto;
  }


  .mobile_view_team_section{
    width: 70vw !important;
  }

  .team_img_mobile:nth-child(odd) {
    display: flex;
    width: 100% !important;
    /* margin-left: 10rem; */
    justify-content: flex-start;
  }

  .team_img_mobile:nth-child(even) {
    display: flex;
    width: 100% !important;
    /* margin-left: 10rem; */
    justify-content: flex-end;
  }



  .team .flex {
    width: 100%;
    height: auto;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 17rem;
    justify-content: center;
  }

  .name_position {
    margin-top: 22rem;
    width: 25rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    color: #000000;
  }
  .team_down_section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
