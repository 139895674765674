.main__image {
  height: 100vh;
  width: 100%;
  position: relative;
}
button {
  transition: all 0.3s linear !important;
}

/* button:hover {
  color: #202e86 !important;
  transition: all 0.3s linear !important;
} */

html,
body {
  width: 100%;
  font-family: "poppins" !important;
  font-size: 62.5% !important;
  overflow-x: hidden !important;
}

a:hover {
  color: #202e86 !important;
  list-style: none;
  border-bottom: none !important;
}

.main__heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input_field .icon {
  padding-left: 3rem;
  padding-right: 2.5rem !important;
  background-image: url(../Images/Shape.png) no-repeat right !important;
  background-size: 2rem;
  z-index: 9999;
  opacity: 0.9;
}

.slider__image {
  height: 100vh;
  width: 100%;
  /* background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); */
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(84, 98, 186, 0.9) 80%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(84, 98, 186, 0.9) 80%
  );
  background-size: 100%;
  background-repeat: no-repeat;
  transition-duration: 1s ease !important;
}

.slider__image.active {
  opacity: 1;
  transition-duration: 1s !important;
  transform: scale(1.08) !important;
}

.slider__image img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
}

.main__heading {
  width: 100%;
  font-size: 4rem;
  font-weight: 800;
  font-family: "poppins";
  text-align: center;
}

.main__title {
  /* width: 100%; */
  height: 4.1rem;
  margin: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.1rem;
  line-height: 3.5rem;
  text-align: center;
  color: #717171;
}

.main__button {
  width: 25rem;
  height: 6rem;
  color: white !important;
  background: #202e86 !important;
  font-size: 2rem;
  margin-top: 2.5rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

/* .main__button:hover {
  transform: translateY(-1rem);
  width: 25rem;
  height: 6rem;
  color: #202e86 !important;
  background: white !important;
  font-size: 2rem;
  margin-top: 2.5rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
} */

/* Home Services */
.home__container {
  height: 45rem !important;
  width: 100%;
  background-image: linear-gradient(
      to left,
      rgba(84, 98, 186, 0.7),
      rgba(82, 78, 183, 0.6)
    ),
    url("../Images/Rectangle1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.main__title-div {
  padding-top: 5rem;
  padding-left: 3.5rem;
}

.title__line {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3.7rem;
  line-height: 5rem;
  letter-spacing: 2px;
  color: #ffffff;
  text-transform: capitalize;
}

/* Why Choose GreenWendEnergy Section */
.item__class {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avator_items {
  width: 100%;
  height: auto;
  display: flex;
  gap: 2rem !important;
}

.avator {
  width: 5rem;
  height: 5rem;
  margin-top: 2rem;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 2.5rem;
}

.paragh {
  width: 40%;
  /* height: 4.5rem; */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  margin-top: 2rem;
  margin-left: 2rem;
  text-transform: capitalize;
  color: #ffffff;
}

.home__container .avator_heading {
  letter-spacing: 0.5rem;
}

/* About Us Services */
.about__div {
  width: 100% !important;
  height: 100%;
  margin-top: 8rem;
}

.about__div .container {
  width: 80% !important;
  padding: 2rem;
}
.about__div .grid {
  display: grid;
  gap: 10rem !important;
}

.about__div .grid-two-column {
  grid-template-columns: 1fr 1fr;
}

.about__image {
  width: 100%;
  height: auto;
  /* margin-top: 80px; */
}

.about_image_section {
  position: relative;
}
.mession__div {
  width: 65%;
  height: 66%;
  background: #fedc3c;
  bottom: -20%;
  left: 17%;
  position: absolute;
}

.paraghraph {
  width: 100%;
  height: auto;
  padding: 2rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 4rem !important;
  /* margin-top: 1px; */
  color: #070a1d;
}

.comma_img {
  width: 4.5rem;
  margin-left: 4rem;
  margin-top: 3rem;
}

.about__us {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  color: #000000;
}

.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 2.9rem;
  margin-top: -0.3rem;
  text-transform: capitalize;
  color: #3f3f3f;
}

.about__paragh {
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.8rem;
  margin-top: 1rem;
  text-align: justify;
  color: #707070;
}

.about__more {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 0.7rem;
  text-transform: uppercase;
  color: #5ea410;
}

.about_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 4.6rem;
  /* or 136% */
  text-transform: capitalize;
  color: #3f3f3f;
}

/* Services */
.Serviced {
  width: 70%;
  height: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  text-align: center;
  text-transform: capitalize;
  color: #3f3f3f;
}

.main_service_section {
  width: 100%;
  height: auto;
  padding: 6rem 0px;
}

.main_service_section .container {
  width: 90%;
  margin-top: 8rem;
}

.serviced__para {
  width: 80%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 3.2rem;
  text-align: center;
  color: #707070;
  margin: 2rem auto;
}

.serviced {
  width: 100%;
  margin-top: 4rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Achivements Page */
.achive__image {
  width: 100%;
  height: 33rem;
  background-image: linear-gradient(
      to left,
      rgba(84, 98, 186, 0.7),
      rgba(82, 78, 183, 0.6)
    ),
    url("../Images/Rectangle4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: fixed;
  position: relative;
  padding: 6rem;
}
/* .achivements_mobile{
  padding-top: 5rem;
} */
.achive__image .container {
  width: 90vw !important;
}
.achive__image .grid {
  display: grid;
  margin-top: 8rem !important;
}
.achive__image .grid-four-column {
  grid-template-columns: repeat(4, 1fr);
}
.our__head {
  top: 10%;
  left: 3%;
  position: absolute;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}
.our__para {
  height: 6rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4rem;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.kilometer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  text-align: center;
  text-transform: capitalize;

  color: white;
  padding-top: 3rem;
  margin: auto;
}

.our__title {
  height: 6rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  text-align: center;
  text-transform: capitalize;
  margin-top: -2rem;
  color: #ffffff;
}
/* .about:nth-child(odd){
display: flex;
width: 100% !important;
justify-content: flex-start;
} */
/* Our Projects */
.our__projects {
  width: 31.1rem;
  height: 5.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 6rem;
  text-align: center;
  text-transform: capitalize;
  color: #3f3f3f;
  margin: auto;
}

.testimony {
  margin-top: 5rem;
  width: 100%;
  height: 43rem !important;
  background-image: linear-gradient(
      to left,
      rgba(84, 98, 186, 0.9),
      rgba(82, 78, 183, 0.9)
    ),
    url("../Images/testimonypicture.png");
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
}

.testimony__head {
  width: 27.4rem;
  height: 4rem;
  padding: 3rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 5rem;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.vector__image {
  position: absolute;
  width: 25rem;
  height: 25rem;
  top: 0rem;
  right: 2rem;
  filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.3));
  /* transform: rotate(5deg); */
  /* background: brown; */
  margin-bottom: 2rem;
}

.user__review {
  position: absolute;
  width: 65vw !important;
  height: auto;
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.user__review p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.9rem;
  /* line-height: 3rem; */
  text-align: center;
  color: #ffffff;
  letter-spacing: 0.6px;
}

.testimony_star {
  width: 100%;
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.testimony_star img {
  width: 4rem;
  height: 4rem;
}
.user_name p {
  color: #ffffff;
  font-family: "poppins";
  font-weight: lighter !important;
  font-size: 1.9rem;
}

/* Mobile Section */
.main_mobile_section {
  width: 100%;
  height: 100%;
}

.main_mobile_section .grid {
  display: grid;
  margin: 8rem auto !important;
  gap: 0 !important;
}
.main_mobile_section .grid-two-column {
  grid-template-columns: 1fr 1fr;
}

.mobile__image {
  width: 100%;
}
.mobile__image img {
  width: 80%;
}

.mobile_section {
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 10rem;
  /* margin-top: 8rem; */
}

.mobile__head {
  /* width: 48.2rem; */
  height: 10rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 4.5rem;
  /* background: brown; */
  margin-top: 9rem;
  color: #070a1d;
}

.mobile__para {
  display: flex;
  justify-content: flex-start;
  width: 55rem;
  /* height: 26rem; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  padding-top: 1rem;
  text-align: justify;
  word-spacing: 0.7px;
  letter-spacing: 0.5px;
  color: #707070;
}

.mobile__download {
  /* height: 2.8rem; */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1rem;
  margin-top: 2rem;
  text-transform: uppercase;
  color: #5ea410;
}

/* Cantact US Section */

.container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex1 {
  width: 100%;
  grid-template-columns: repeat(1, 2fr);
  /* display: grid; */
  gap: 5rem !important;
}
.form-group {
  width: 30rem;
}

.small {
  position: relative;
}

.small {
  position: absolute;
  font-size: 1.4rem;
  color: #ffffff;
  position: absolute;
  top: -2.5rem !important;
  right: 12%;
}

.form-control {
  height: 4rem;
  border-radius: 0;
}

.textarea textarea {
  height: 15rem;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttons .btn {
  text-transform: uppercase;
  font-size: medium;
  font-weight: 500;
  border-radius: 1rem;
  padding: 1rem 4rem;
  background: #202e86;
}

.form-control:focus {
  background: rgba(82, 78, 183, 0.9);
  color: #ffffff;
  outline: none !important;
}

.countrycode {
  width: 100%;
  background: none !important ;
  border: 1px solid white;
  height: 43px !important;
  color: white;
  font-size: 1.4rem !important;
  padding-left: 1rem;
}

.countrycode:focus {
  outline: none;
}

/* ********************** */

/* ********************************** */

.checkbox {
  position: relative;
}
.checkbox input {
  width: 1.7rem !important;
  height: 1.7rem;
  position: absolute;
  top: -2.7rem !important;
  right: 43.5%;
  border-radius: 0px !important;
  border: 1px solid #ffffff !important;
  background-color: rgba(82, 78, 183, 0.5) !important;
  cursor: pointer;
}

.checkbox input:focus {
  /* background: transparent !important; */
}

/* input[type=text] {
  background-color: #3CBC8D;
  color: white;
} */

.form-group [type="number"]::placeholder {
  color: #dfdfdf;
  font-size: 1.4rem !important;
}

.form-group [type="email"]::placeholder {
  color: #dfdfdf;
  font-size: 1.4rem !important;
}
.form-group [type="text"]::placeholder {
  color: #dfdfdf;
  font-size: 1.4rem !important;
}

.textarea textarea::placeholder {
  color: #dfdfdf;
  font-size: 1.4rem !important;
}

.form-group [type="phone"]:focus {
  color: #dfdfdf;
  font-size: 1.4rem !important;
}
.form-group [type="email"]:focus {
  color: #dfdfdf;
  font-size: 1.4rem !important;
}

.form-group [type="text"]:focus {
  color: #dfdfdf;
  font-size: 1.4rem !important;
}

.textarea textarea:focus {
  color: #dfdfdf;
  font-size: 1.4rem !important;
}

.cantact__us {
  width: 100%;
  height: auto;
  background-image: linear-gradient(
      to left,
      rgba(82, 78, 183, 0.9),
      rgba(82, 78, 183, 0.9)
    ),
    url("../Images/phone.png");
}

.cantact__head {
  margin-top: 3rem !important;
  height: 4.5rem;
  word-spacing: 2px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 4.5rem;
  text-align: center;
  color: #ffffff;
}

.contact__para {
  letter-spacing: 1px;
  word-spacing: 2px;
  height: 7.1rem;
  margin-top: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: lighter !important;
  font-size: 2.8rem;
  line-height: 3rem;
  text-align: center;
  color: #ffffff;
}

.form__feild {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  height: 50rem;
  width: 70%;
  background: brown;
}

.solor_project {
  font-weight: 600;
}

label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 3.5rem;
  color: #ffffff;
}

.label1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  color: #ffffff;
}

.form-control::placeholder {
  color: white;
  font-size: 1.4rem;
}
.form-control {
  box-sizing: border-box;
  background: rgba(84, 98, 186, 0.2);
  border: 1px solid #ffffff;
  color: #dfdfdf;
  outline: none;
}

.message__feild {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: green;
}

.inputMessage {
  box-sizing: border-box;
  width: 62rem;
  height: 12rem;
  background: rgba(84, 98, 186, 0.2);
  border: 1px solid #ffffff;
  padding: 2rem;
  margin: auto;
  color: #dfdfdf;
}

.inputMessage::placeholder {
  color: white;
}

.form__button {
  width: 20rem;
  height: 5rem;
  background: #202e86;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
  /* margin-top: 200px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 2.5rem;
  text-align: center;
  text-transform: uppercase;
}
.buttons {
  padding: 3rem 0 !important;
}
.buttons .btn {
  text-transform: uppercase;
  font-size: medium;
  font-weight: 500;
  border-radius: 1rem;
  padding: 1rem 4rem;
  color: #ffffff !important;
  background: #202e86;
}
input,
input::-webkit-input-placeholder {
  font-size: 1.7rem !important;
}
textarea::-webkit-input-placeholder {
  font-size: 1.7rem !important;
}
/***************************** media quries*************************** */

@media screen and (max-width: px) {
  /* .about__div .container{
  width: 90vw !important;
  padding: 2rem;
} */
  .about__div .grid {
    display: grid;
    gap: 7rem !important;
  }
  .paraghraph {
    width: 100%;
    height: auto;
    padding: 2rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 3rem;
    /* margin-top: 1px; */
    color: #070a1d;
  }
}

@media screen and (max-width: px) {
  .about__div .container {
    width: 90% !important;
    /* padding: 2rem; */
  }
  .about__div .grid {
    gap: 6rem !important;
  }

  .paraghraph {
    width: 100%;
    height: auto;
    padding: 2rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 3rem;
    /* margin-top: 1px; */
    color: #070a1d;
  }
  .mobile__para {
    display: flex;
    justify-content: flex-start;
    width: 50rem;
    padding: 1rem !important;
    /* height: 26rem; */
    padding: 0 !important;
  }
  .achive__image .grid {
    display: grid;
    margin-top: 9rem;
    gap: 4rem !important;
  }
  .achive__image .grid-four-column {
    grid-template-columns: repeat(4, 1fr);
  }
  .mobile__head {
    /* width: 48.2rem; */
    height: 10rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 4.5rem;
    /* background: brown; */
    margin-top: 6rem;
    color: #070a1d;
}
}

@media screen and (max-width: px) {
  .about__div .container {
    /* width: 90vw !important; */
    /* padding: 2rem; */
  }
  .about__div .grid {
    display: grid;
    gap: 5rem !important;
  }
  .paraghraph {
    width: 100%;
    height: auto;
    padding: 2rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1.9rem;
    line-height: 3rem;
    /* margin-top: 1px; */
    color: #070a1d;
  }
  .mobile__para {
    display: flex;
    justify-content: flex-start;
    width: 45rem;
    /* height: 29rem; */
    padding: 0 !important;
  }
}

@media screen and (max-width: 1024px) {
  .countrycode {
    height: 36px !important;
  }

  .main__image {
    width: 100%;
    height: 60rem;
    align-items: center;
  }
  .avator_items {
    padding: 1rem;
    width: 100%;
    height: auto;
    display: flex;
    gap: 0rem !important;
  }

  .slider__image {
    height: 60rem;
    width: 100%;
    /* background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); */
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(84, 98, 186, 0.9) 80%
    );
    -webkit-mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(84, 98, 186, 0.9) 80%
    );
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .slider__image img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .home__container {
    height: 39rem !important;
    width: 100%;
    background-image: linear-gradient(
        to left,
        rgba(84, 98, 186, 0.7),
        rgba(82, 78, 183, 0.6)
      ),
      url(../Images/Rectangle1.png) !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    background-image: fixed;
  }

  .main__title-div {
    padding-top: 2rem;
    padding-left: 3.5rem;
  }

  .title__line {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 3.7rem;
    line-height: 5rem;
    letter-spacing: 2px;
    color: #ffffff !important;
    text-transform: capitalize;
  }
  .home__container {
    width: 100%;
    height: auto;
  }
  /* about us section */

  .about__div {
    width: 100% !important;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem auto !important;
    justify-content: center;
    gap: 0rem !important;
  }

  .about__div .container {
    width: 95% !important;
    margin: 0px auto !important;
  }

  .about__div .grid-two-column {
    grid-template-columns: 1fr;
  }

  .about__div .grid {
    gap: 1rem !important;
  }
  .about_image_section {
    height: auto;
    width: 100% !important;
    order: 2;
    position: relative;
    padding: 2rem;
  }
  .about__image {
    width: 100%;
    height: auto;

    /* margin-top: 80px; */
  }

  .about__paragh {
    width: 100% !important;
  }

  .paraghraph {
    font-size: 2.8rem;
    line-height: 5rem !important;
  }

  .comma_img {
    width: 5.5rem !important;
    margin-left: 4rem;
    margin-top: 5rem;
  }
  /* end of about us secyion */

  /* Service section */

  .serviced {
    margin-top: 5rem !important ;
  }

  .main_service_section .container {
    width: 95%;
    padding: 2rem;
    /* margin-top: 4rem; */
  }

  .main_service_section .container {
    width: 95% !important;
    /* padding: 2rem; */
    margin-top: 2rem !important;
  }

  .serviced__para {
    width: 100% !important;
    height: auto;
  }

  /* end of servicd xdcion */

  /* Achivement section */

  .achive__image {
    width: 100% !important;
  }
  .achivements_mobile {
    gap: 1rem !important;
  }
  .achive__image .grid {
    display: grid;
    gap: 4rem !important;
    margin-top: 4rem !important;
  }
  .achive__image .grid-four-column {
    grid-template-columns: repeat(2, 1fr);
  }
  /* end of achievement */

  /* contact us */

  .cantact__us {
    width: 100% !important;
    height: auto !important;
  }

  .flex1 {
    display: block !important;
  }
  .form-group {
    width: 60rem !important;
  }
  .form-control {
    height: 6rem;
    margin: 1rem 0px;
  }
  .textarea_mobile_view {
    margin-top: 3rem !important;
  }
  label {
    line-height: 1.5rem !important;
  }
  .textarea .form-control {
    height: 24rem;
  }

  .small {
    position: relative;
  }

  .small {
    position: absolute;
    font-size: 1.4rem;
    color: #ffffff;
    position: absolute;
    top: -2rem !important;
    left: 2.5rem !important;
  }

  .checkbox {
    position: relative;
  }
  .checkbox input {
    position: absolute;
    top: -2.5rem !important;
    left: 0 !important;
    border-radius: 0px !important;
    border: 1px solid #ffffff !important;
    background-color: rgba(82, 78, 183, 0.5) !important;
    cursor: pointer;
  }
  .countrycode {
    margin: 1rem auto !important;
  }

  /* Testimony section */

  .testimony {
    width: 100% !important;
    /* height: ; */
  }
  .testimony {
    margin-top: 5rem;
    width: 100%;
    height: 55rem !important;
    background-image: linear-gradient(
        to left,
        rgba(84, 98, 186, 0.9),
        rgba(82, 78, 183, 0.9)
      ),
      url("../Images/testimonypicture.png");
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .vector__image {
    position: absolute;
    width: 20rem;
    height: 20rem;
    top: 2rem;
    right: 0%;
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.3));
  }
  .vector__image img {
    width: 100%;
    height: auto;
  }

  /* mobile section */
  /* mobile section */
  .mobile_section {
    width: 100% !important;
    height: auto !important;
  }
  .mobile__image {
    width: 100%;
    height: 100%;
    margin-top: 5rem;
    order: 2 !important;
  }
  .mobile__image img {
    width: 100%;
    height: auto;
  }
  .main_mobile_section .grid-two-column {
    grid-template-columns: 1fr;
  }
  .mobile__download {
    display: none;
  }
  .mobile__para {
    width: 95% !important;
    height: auto;
    margin: 0 auto;
    padding: 1rem !important;
  }

  .mobile__head {
    width: 90% !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 4.5rem;
    margin-top: 3rem;
    color: #070a1d;
    padding: 1rem !important;
  }
}
/* ************************************************** */
@media (max-width: 768px) {
  .main__image {
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .home__container {
    width: 100%;
    height: auto;
  }
  /* about us section */

  .about__div {
    width: 90% !important;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem auto;
    justify-content: center;
    gap: 0rem !important;
  }
  .about__div {
    width: 100% !important;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 7rem auto !important;
    justify-content: center;
    gap: 0rem !important;
  }

  .about__div .container {
    width: 95% !important;
  }

  .about__paragh {
    width: 100% !important;
  }

  .paraghraph {
    font-size: 2.7rem;
    line-height: 5rem !important;
  }
  .comma_img {
    width: 5.5rem !important;
    margin-left: 4rem;
    margin-top: 5rem;
  }

  /* end of about us secyion */ /* Testimony section */

  .testimony {
    width: 100% !important;
    /* height: ; */
  }
  .testimony {
    margin-top: 5rem;
    width: 100%;
    /* height: 40vh; */
    background-image: linear-gradient(
        to left,
        rgba(84, 98, 186, 0.9),
        rgba(82, 78, 183, 0.9)
      ),
      url("../Images/testimonypicture.png");
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .user__review {
    position: absolute;
    width: 90% !important;
    height: auto;
    position: absolute;
    top: 69%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    /* margin: auto; */
  }
  .vector__image {
    top: 7rem;
    right: 0%;
  }

  /* Service section */

  .serviced {
    margin-top: 5rem !important ;
  }

  .serviced__para {
    width: 100% !important;
    height: auto;
  }

  /* end of servicd xdcion */

  /* Achivement section */

  .achive__image {
    width: 100% !important;
  }

  .achive__image .grid {
    display: grid;
    gap: 4rem !important;
    margin-top: 6rem;
  }
  .achive__image .grid-four-column {
    grid-template-columns: repeat(2, 1fr);
  }

  /* end of achievement */

  /* contact us */

  .cantact__us {
    width: 100% !important;
    height: auto !important;
  }

  .flex1 {
    display: block !important;
  }
  .form-group {
    width: 55rem !important;
  }
  .form-control {
    height: 6rem;
    margin: 1rem 0px;
  }
  label {
    line-height: 1.5rem !important;
  }
  .textarea .form-control {
    height: 24rem;
  }

  /* Testimony section */

  .testimony {
    width: 100% !important;
    /* height: ; */
  }

  .vector__image img {
    width: 100%;
    height: auto;
  }

  /* mobile section */
  .mobile_section {
    width: 100% !important;
    height: auto !important;
  }
  .mobile_para_main {
    width: 90% !important;
    display: flex;
    margin: 1rem auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* padding: 2rem; */
  }

  .mobile__image img {
    width: 100%;
    height: auto;
  }
  .mobile__download {
    display: none;
  }

  .mobile__head {
    width: 100%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 4.5rem;
    margin-top: 3rem;
    color: #070a1d;
  }
}

/* for extra small devices 662 */

@media (max-width: 768px) {
  .home__container {
    /* height: 100vh; */
    width: 100%;
    background: #cbd2ff;
  }

  .main__title-div {
    padding-top: 3rem;
    padding-left: 2.5rem;
  }

  .title__line {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    /* line-height: 75px; */

    text-transform: capitalize;

    color: #3f3f3f;
  }

  .image img {
    width: 100%;
    height: 100%;
  }

  /* About us page */

  .mobile_view_main_page {
    order: 1;
  }
}

/* for extra small devices 662 */

@media only screen and (max-width: 576px) {
  .about__paragh {
    width: 100% !important;
  }

  .paraghraph {
    font-size: 2.6rem;
  }

  .serviced {
    margin-top: 0 !important;
  }

  .flex1 {
    display: block !important;
  }
  .form-group {
    width: 50rem !important;
  }

  .testimony {
    margin-top: 5rem;
    width: 100%;
    height: 45rem !important;
    background-image: linear-gradient(
        to left,
        rgba(84, 98, 186, 0.9),
        rgba(82, 78, 183, 0.9)
      ),
      url("../Images/testimonypicture.png");
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .vector__image {
    position: absolute;
    width: 15rem;
    height: 15rem;
  }

  .user__review {
    position: absolute;
    width: 90% !important;
    height: auto;
    position: absolute;
    top: 69%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    padding-bottom: 4rem !important;
  }
  .vector__image {
    top: 3rem;
    right: 0%;
  }
  .user__review .user_name p {
    color: #ffffff;
    font-family: "poppins";
    font-weight: lighter !important;
    font-size: 2rem;
    font-weight: 300 !important;
  }

  .mobile_para_main {
    width: 90% !important;
    display: flex;
    margin: 1rem auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* padding: 2rem; */
  }

  .about__paragh {
    width: 100% !important;
  }

  .serviced {
    margin-top: 0 !important;
  }

  .flex1 {
    display: block !important;
  }
  .form-group {
    width: 50rem !important;
  }
}
/* ****************************************************************** */
@media screen and (max-width: 492px) {
  .paraghraph {
    font-size: 2.5rem;
  }

  .testimony {
    margin-top: 5rem;
    width: 100%;
    height: 50rem !important;
    background-image: linear-gradient(
        to left,
        rgba(84, 98, 186, 0.9),
        rgba(82, 78, 183, 0.9)
      ),
      url("../Images/testimonypicture.png");
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .testimony_star img {
    width: 3rem;
    height: 3rem;
  }
  .mobile_para_main {
    width: 95% !important;
    display: flex;
    margin: 1rem auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    margin-bottom: 2rem !important;
  }

  .user__review {
    position: absolute;
    width: 85%;
    height: auto;
    position: absolute;
    top: 69%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    padding-bottom: 4rem !important;
  }
  .vector__image {
    top: 5rem;
    right: 0%;
  }
}
/* ****************************************************************** */
@media screen and (max-width: 380px) {
  .paraghraph {
    font-size: 2.6rem;
  }
  .user__review {
    position: absolute;
    width: 85%;
    height: auto;
    position: absolute;
    top: 69%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    padding-bottom: 4rem !important;
  }
  .testimony {
    margin-top: 5rem;
    width: 100%;
    height: 55rem !important;
    background-image: linear-gradient(
        to left,
        rgba(84, 98, 186, 0.9),
        rgba(82, 78, 183, 0.9)
      ),
      url("../Images/testimonypicture.png");
    position: relative;
    background-size: cover !important;
    background-repeat: no-repeat;
  }
  .testimony_star img {
    width: 3rem;
    height: 3rem;
  }
  .mobile_para_main {
    width: 95% !important;
    display: flex;
    margin: 1rem auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    margin-bottom: 2rem !important;
  }
  .vector__image {
    top: 7rem;
    right: 0%;
  }
}


