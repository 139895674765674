html {
  font-size: 62.5%;
}
.loaction_phone_message {
  width: 100%;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.loaction_phone_message .container-fluid {
  width: 73% !important;
}

footer .container-fluid {
  width: 73% !important;
}

.phone_image {
  margin: 0 !important;
}

.phone {
  width: 100%;
  height: 9rem;
  padding: 1rem;
  /* identical to box height */
  background: #7681c8;
}

.phone .phone_data {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  /* margin-left: 1rem; */
  line-height: 2rem;
  color: #ffffff;
}
.message {
  width: 100%;
  height: 9rem;
  padding: 1rem;
  background: #616fc8;
}

.message .message_data {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  /* margin-left: 1rem; */
  line-height: 2rem;
  color: #ffffff;
}
.location {
  width: 100%;
  height: 9rem;
  padding: 1rem;

  background: #5462ba;
}

.location .location_data {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  /* margin-left: 1rem; */
  line-height: 2rem;

  color: #dfdfdf;
}

/* footer section */

.footer_para {
  width: 100%;
  height: auto;
}

.footer_para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.8rem;
  text-align: justify;

  color: #707070;
}

footer .row {
  margin-top: 5rem !important;
}

li {
  list-style-type: none;
}
.footer_heading {
  margin-top: 1rem;
}
.footer_lists {
  margin-top: 5rem;
  /* background: red; */
}

.footer_lists li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  /* identical to box height */

  margin: 1.2rem 0px;
  text-align: justify;

  color: #070a1d;
}

.footer_lists p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.8rem;
  text-align: justify;

  color: #707070;
}

.footer_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  /* identical to box height, or 180% */
  color: #070a1d;
}

.last_line h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.5rem;
  text-align: justify;
  color: #707070;
  margin: 0 auto !important;
}

.footer_icons {
  width: 100%;
  margin: 4rem auto !important;
}

/* end of footer section */

/* Footer Input Feild */
.main {
  /* width: 100%; */
  margin-top: 3rem;
  box-sizing: border-box;
  height: 3.1rem;
  /* margin-left: 25px; */
  position: relative;
  /* background-color: rebeccapurple; */
}

.iconImage {
  position: absolute;
  right: 0;
  top: 0;
  height: 3.1rem;
  line-height: 4.1rem;
  /* width: 31px; */
  /* float: right; */
  padding: 0.5rem 0.5rem;
  /* color: white; */
  background: #717171;
}

.input {
  /* position: absolute; */
  float: right;
  box-sizing: border-box;
  font-size: 1.4rem;
  height: 3.1rem;
  width: 100%;
  border: 0.15rem solid #717171;
  padding: 1rem;
  outline: none;
}

/* For Icons */

.img {
  width: 100%;
  height: auto;
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 48px; */
}

.footer_icons .mobile_view {
  display: none;
}

.main input::-webkit-input-placeholder {
  font-size: 1.5rem !important;
}

footer button:hover{
  color: #5462ba;
}
/* **********************************for responsive *************************** */

@media (max-width: 1024px) {
  .loaction_phone_message .container-fluid {
    width: 100% !important;
  }

  /* .loaction_phone_message .row{
  margin-right: calc(-1.5rem * -.5);
  margin-left: calc(-1.5rem * -.5);
} */

  .loaction_phone_message .phone,
  .message,
  .location {
    width: 100% !important;
    margin: 0px;
    gap: 2rem;
  }

  .phone {
    width: 100%;
    height: 15rem;
    padding: 0rem !important;
    background: #7681c8;
  }
  .phone_image img {
    margin-left: 5rem;
    width: 5rem !important;
  }

  .message {
    width: 100%;
    height: 15rem;
    padding: 0rem !important;
    background: #616fc8;
  }

  .message_image img {
    margin-left: 5rem;

    width: 5rem !important;
  }

  .location {
    width: 100%;
    height: 15rem;
    padding: 0rem !important;
    background: #5462ba;
  }

  .location_image img {
    margin-left: 5rem;
    width: 5rem !important;
  }

  .footer_para {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: justify;
    /* margin-bottom: 2rem !important; */
    color: #707070;
  }
  .footer_lists {
    margin-top: 0px;
    /* background: red; */
  }
  .footer_icons .imgs {
    display: none;
  }
  .footer_icons .mobile_view {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    color: #070a1d;
  }
  .footer_icons {
    display: block;
  }
  .last_line h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.5rem;
    text-align: justify;
    color: #707070;
  }

  footer .container-fluid {
    width: 90% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo_footer_img {
    width: 25rem !important;
    margin: -1rem 0 !important;
  }

  
.last_line h2 {

  text-align: center;
  color: #707070;
  margin: 1rem auto !important;
}

.footer_icons {
  width: 100%;
  margin: 1rem auto !important;
}
.img{
  display: flex;
  align-items: center;
  gap: 6rem;
}
}

@media (max-width: 768px) {
  .loaction_phone_message .container-fluid {
    width: 100% !important ;
  }

  .loaction_phone_message .phone,
  .message,
  .location {
    width: 100% !important;
    margin: 0px auto;
  }

  .phone {
    width: 100%;
    height: 15rem;
    /* padding: 1rem; */
    /* identical to box height */
    background: #7681c8;
  }
  .phone_image img {
    margin-left: 5rem;

    width: 5rem !important;
  }

  .message {
    width: 100%;
    height: 15rem;
    /* padding: 1rem; */
    background: #616fc8;
  }

  .message_image img {
    margin-left: 5rem;

    width: 5rem !important;
  }

  .location {
    width: 100%;
    height: 15rem;
    /* padding: 1rem; */

    background: #5462ba;
  }

  .location_image img {
    margin-left: 5rem;
    width: 5rem !important;
  }

  .location_data,
  .phone_data,
  .message_data {
    font-size: 1.4rem !important;
  }

  .footer_para {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-align: justify;
    margin-bottom: 1rem;
    color: #707070;
  }
  .footer_lists {
    margin-top: 0px;
    /* background: red; */
  }
  .footer_icons .imgs {
    display: none;
  }
  .footer_icons .mobile_view {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 31rem;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    color: #070a1d;
  }
  .footer_icons {
    display: block;
  }
  .last_line h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.5rem;
    text-align: justify;
    color: #707070;
  }

  footer .container-fluid {
    width: 90% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .last_line h2 {

  text-align: center;
  color: #707070;
  margin: 1rem auto !important;
}

.footer_icons {
  width: 100%;
  margin: 1rem auto !important;
}

  /* .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    --bs-gutter-x: -0.5rem !important;
  } */
}

/* **********************************for responsive *************************** */
