html {
  font-size: 62.5%;
}

.serice_work_section {
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(
      to left,
      rgba(84, 98, 186, 0.8),
      rgba(84, 98, 186, 0.8)
    ),
    url("../Images/service_work_img.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 9rem;
}

.serice_work_section .work_heading {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.work_heading h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 4rem;
  letter-spacing: 0.04em;
  color: #ffffff;
  padding: 3rem;
}

.work_heading p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  text-align: center;
  color: #ffffff;
}

.serice_work_section .container {
  width: 70%;
  height: auto;
  /* background: red; */
  margin: 0px auto !important;
}
.serice_work_section .grid {
  display: grid;
  margin: 2rem auto !important;
  gap: 5rem !important;
}
.grid-three-column {
  grid-template-columns: repeat(3, 1fr);
}
.service_work1 {
  /* background: green; */
  width: 30rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.service_work1 .line_one {
  position: absolute;
  left: 70%;
  top: 25%;
  width: 77%;
}
.service_work1 .line_two {
  position: absolute;
  left: 70%;
  top: 1%;
  width: 77%;
}

.serice_work_section .figure {
  width: 7rem;
  height: 7rem;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashed_circle {
  width: 8rem;
  height: 8rem;
  position: absolute;
  top: -0.5rem;
  left: 11rem;
  border: 0.3rem dashed white;
  border-radius: 50%;
}
/* .dashed-circle-active{
    -webkit-animation: spin 9s ease-in-out;
} */
.dashed_circle:hover {
  -webkit-animation: spin 0.9s ease-in-out;
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(180deg);
  }
}

@-moz-keyframes spin {
  100% {
    -webkit-transform: rotate(-180deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(180deg);
  }
}

 figure img {
  width: 60%;
}

.service_work1 p {
  margin-top: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  text-align: center;

  color: #ffffff;
}

/* media queries */

.mobile_view_offering {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .serice_work_section {
    width: 100%;
    height: auto;
    padding: 3rem;
    background-image: linear-gradient(
        to left,
        rgba(84, 98, 186, 0.8),
        rgba(84, 98, 186, 0.8)
      ),
      url("../Images/service_work_img.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .serice_work_section .work_heading {
    width: 95% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .serice_work_section .container {
    width: 100%;
    height: auto;
    /* background: red; */
    margin: 0px auto;
  }
  .grid {
    display: grid;
    gap: 5rem !important;
  }
  .grid-three-column {
    grid-template-columns: repeat(3, 1fr);
  }

  .service_work1 {
    /* background: green; */
    width: 30rem;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  .serice_work_section .figure {
    width: 7rem;
    height: 7rem;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* mobile view */

  .mobile_view_offering {
    width: 100%;
    height: auto;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .mobile_view_offering::-webkit-scrollbar {
    overflow: hidden;
  }
  .line_one,
  .line_two {
    display: none;
  }

  .desktop_view {
    display: none;
  }
  .mobile_view_offering {
    display: block !important;
  }

  .mobile_view_offering {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile_view_offering .service_work1 {
    margin: 2rem auto;
  }
}

@media screen and (max-width: 768px) {
  .serice_work_section {
    width: 100%;
    height: auto;
    padding: 3rem;
    background-image: linear-gradient(
        to left,
        rgba(84, 98, 186, 0.8),
        rgba(84, 98, 186, 0.8)
      ),
      url("../Images/service_work_img.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .serice_work_section .work_heading {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .serice_work_section .container {
    width: 100%;
    height: auto;
    /* background: red; */
    margin: 0px auto;
  }
  .grid {
    display: grid;
    gap: 5rem !important;
  }
  .grid-three-column {
    grid-template-columns: repeat(3, 1fr);
  }

  .service_work1 {
    /* background: green; */
    width: 30rem;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  .serice_work_section .figure {
    width: 7rem;
    height: 7rem;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* mobile view */

  .mobile_view_offering {
    width: 100%;
    height: auto;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .mobile_view_offering::-webkit-scrollbar {
    overflow: hidden;
  }
  .line_one,
  .line_two {
    display: none;
  }
  .work_heading h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2.7rem;
    /* line-height: 4rem; */
    letter-spacing: 0.04em;
    color: #ffffff;
    padding: 1rem !important;
  }
}
