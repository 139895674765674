html {
  font-size: 62.5%;
}

.solution_hero_section {
  width: 100%;
  height: 85vh;
  background: url(../Images/solution_page_img1.png);
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: 30% 20%; */
  position: relative;
}
.solution_hero_section::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(84, 98, 186, 0.8),
    rgba(84, 98, 186, 0.7)
  );
}

.solution_hero_section .container {
  width: 90% !important;
  display: flex;
  justify-content: start;
  padding: 0 !important;
}

.solution_hero_left {
  width: 40% !important;
  margin-top: 14rem;
}

.solution_hero_left h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.5rem;
  z-index: 99;
  opacity: 0.9;
  color: #ffffff;
}

.solution_hero_left h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.8rem;
  line-height: 6.5rem;
  opacity: 0.98;
  z-index: 999;

  color: #ffffff;
}

.solution_hero_left p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  letter-spacing: 0.5px;
  /* or 150% */
  opacity: 0.98;
  z-index: 999;
  text-align: justify;
  color: #ededed;
}

/* sketching section */

.solutionpage_sketching_section {
  width: 100%;
  height: auto;
  /* background: red; */
  margin-top: 8rem !important;
}

.solutionpage_sketching_section .container {
  width: 73%;
  /* height: 60vh; */
  margin: 3rem auto;
}
.grid {
  display: grid;
  gap: 9rem !important;
}

.solutionpage_sketching_section .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}
.solutionpage_sketch_image {
  width: 100%;
}
.solutionpage_sketch_image img {
  width: 100%;
}

.solutionpage_sketch_right {
  width: 100%;
  height: 100%;
  /* background: green; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.solutionpage_sketch_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.7rem;
  line-height: 5rem;
  word-spacing: 1.6px;
  /* or 115% */

  letter-spacing: 0.04em;

  color: #070a1d;
}

.solutionpage_sketch_right p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  word-spacing: 1px;
  letter-spacing: 0.5px;
  text-align: justify;
  margin-top: 2rem;
  color: #707070;
}

.solutionpage_sketch_right .sketch_design {
  font-weight: 600;
  color: #5462ba;
}

.sketch_links {
  color: #707070;
  border-bottom: 2px solid#707070;
}

/* end sketching section */

/* fed_up section */

.solutionpage_fed_up_section {
  width: 100%;
  height: auto;
  /* background: red; */
}

.solutionpage_fed_up_section .container {
  width: 73%;
  /* height: 60vh; */
  margin: 6rem auto;
}
.solutionpage_fed_up_section .grid {
  display: grid;
  gap: 9rem !important;
}

.solutionpage_fed_up_section .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}
.solutionpage_fed_up_image {
  width: 100%;
}
.solutionpage_fed_up_image img {
  width: 100%;
}

.solutionpage_fed_up_right {
  width: 100%;
  height: 100%;
  /* background: green; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.solutionpage_fed_up_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.7rem;
  line-height: 5rem;
  word-spacing: 1.6px;
  /* or 115% */

  letter-spacing: 0.04em;

  color: #070a1d;
}

.solutionpage_fed_up_right p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  word-spacing: 0.1rem;
  letter-spacing: 0.5px;
  text-align: justify;
  margin-top: 2rem;
  color: #707070;
}

.solutionpage_fed_up_right .sketch_design {
  font-weight: 600;
  color: #5462ba;
}

/* end fed-up section */

/* fed_up section */

.solutionpage_wisely_section {
  width: 100%;
  height: auto;
  /* background: red; */
}

.solutionpage_wisely_section .container {
  width: 73%;
  /* height: 60vh; */
  margin: 7rem auto;
}
.grid {
  display: grid;
  gap: 9rem !important;
}

.solutionpage_wisely_section .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}
.solutionpage_wisely_image {
  width: 100%;
}
.solutionpage_wisely_image img {
  width: 100%;
}

.solutionpage_wisely_right {
  width: 100%;
  height: 100%;
  /* background: green; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.solutionpage_wisely_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.7rem;
  line-height: 5rem;
  word-spacing: 1.6px;
  /* or 115% */

  letter-spacing: 0.04em;

  color: #070a1d;
}

.solutionpage_wisely_right p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  word-spacing: 1px;
  letter-spacing: 0.5px;
  text-align: justify;
  margin-top: 2rem;
  color: #707070;
}

.solutionpage_wisely_right .sketch_design {
  font-weight: 600;
  color: #5462ba;
}

/* end wisely section */

/* packages cards */

.solution_page_packages_cards {
  width: 100%;
  height: auto;
  /* background: red; */
}
.solution_page_packages_cards .container {
  width: 71%;
  margin: 0px auto;
}
.solution_page_packages_cards .packages_cards_heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.solution_page_packages_cards .packages_cards_heading h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 4rem;

  margin-bottom: 1rem;
  text-align: center;
  text-transform: capitalize;

  color: #3f3f3f;
}

.packages_cards_blue {
  color: #5462ba;
  font-weight: bold;
}
.solution_page_packages_cards .packages_cards_heading p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  /* or 180% */

  text-align: center;

  color: #707070;
}

.packages_cards_div {
  width: 100%;
  height: 40rem;
  /* background: grey; */
}

.packages_cards_div .container {
  width: 84%;
  height: auto;
  margin: 0px auto;
}
.packages_cards_div .grid {
  display: grid;
  gap: 0px !important;
  margin: 1rem auto !important;
}
.packages_cards_div .grid-four-column {
  grid-template-columns: repeat(4, 1fr);
}

.packages_cards_div .package_cards_data {
  margin: 6rem 0px;
  width: 28rem;
  height: 30rem;
  border: 1px solid blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s linear;
}

.packages_cards_first {
  border-left: none !important;
}
.packages_cards_second {
  border-left: none !important;
}
.packages_cards_third {
  border-left: none !important;
}
/* .pacda{
    width:150px;
    height:45px;
    background:black;
    transition: height .3s cubic-bezier(0.175, 0.885, 0.32, 1.275) ;
    transform: rotatex(180deg) translate3d(0, -200px,0);
}
.buttom:hover{
    height:180px
} */

.package_cards_data:hover {
  cursor: pointer;
  color: white !important;
  height: 33rem;
  margin: 5rem 0;
  margin-top: 3rem;
}
.package_cards_data .card_link {
  position: relative;
  z-index: 3;
  color: #ffff;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.5s linear;
}

.package_cards_data:hover .card_link {
  opacity: 1;
  transform: translateY(0px);
}

.package_cards_data:hover {
  background: #5462ba;
  color: white !important;
}
.package_cards_data:hover h2 {
  color: white;
}

.package_cards_data:hover p {
  color: white;
}

.package_cards_data:hover img {
  filter: brightness(0) invert(1);
  color: white;
}

.packages_cards_div h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 6rem;
  text-align: center;
  color: #070a1d;
}
.packages_cards_div .packages_cards_para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 2.6rem;
  text-align: center;
  color: #707070;
}

.packages_cards_div .packages_pkr {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 6rem;
  text-align: center;
  margin-top: 2rem;

  color: #070a1d;
}
.packages_cards_div img {
  width: 8rem;
}

/* packages cards end */

/* package_deta section */

.solutionpage_package_datails_section {
  width: 100%;
  height: auto;
}

.solutionpage_package_datails_section .container {
  width: 73%;
  margin: 7rem auto;
}
.grid {
  display: grid;
  gap: 9rem !important;
}

.solutionpage_package_datails_section .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}
.solutionpage_package_datails_image {
  width: 100%;
}
.solutionpage_package_datails_image img {
  width: 100%;
}

.solutionpage_package_datails_right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.solutionpage_package_datails_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.7rem;
  line-height: 5rem;
  word-spacing: 1rem;
  letter-spacing: 0.04em;
  color: #070a1d;
}

.solutionpage_package_datails_right p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  word-spacing: 0.1rem;
  letter-spacing: 0.5px;
  text-align: justify;
  margin-top: 2rem;
  color: #707070;
}

.solutionpage_package_datails_right .package_details_list {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  word-spacing: 0.1rem;
  letter-spacing: 0.5px;
  text-align: justify;
  color: #707070;
}
.package_details_list li {
  list-style-type: disc;
}
.package_details_list .inludes {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 3rem;
  word-spacing: 0.1rem;
  letter-spacing: 0.5px;
  text-align: justify;
  color: #707070;
}
/* end packages_details section */

/* button section start */
.button_section {
  width: 100%;
  height: auto;
  /* background: red; */
}

.button_section .btn {
  width: 22rem;
  height: 5rem;
  background: #202e86;
  box-shadow: 0px 0.2rem 1rem rgba(0, 0, 0, 0.4);
  border-radius: 1rem;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 3.5rem;
  /* or 117% */
  text-align: center;
  color: #ffffff;
}
/* button section end */

/* start portfolio section */

.our_portfolio_section {
  width: 100%;
  height: auto;
  margin-top: 3rem;
}
.our_portfolio_section .container {
  width: 76%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.portfolio_heading h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3rem;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: capitalize;

  color: #5462ba;
}
.portfolio_heading p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 2.4rem;

  color: #000000;
}

.our_portfolio_section .servies_img1 {
  margin-left: 0 !important;
}

.our_portfolio_section .portfolio_right h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 4.5rem;
  color: #000000;
}

.our_portfolio_section .portfolio_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 2.8rem;
  line-height: 4rem;
  /* or 136% */

  text-transform: capitalize;

  color: #3f3f3f;
}

.our_portfolio_section .portfolio_right p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  word-spacing: 1px;
  text-align: justify;
  color: #707070;
  margin-top: 1rem;
}

.our_portfolio_section .portfolio_right button {
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
  text-transform: uppercase;
  color: #5ea410;
  transition: all 0.5s linear;
}

.our_portfolio_section .portfolio_right button:hover {
  border-bottom: 2px solid #5ea410;
}

/* media query */

/* 1200 */
@media screen and (max-width: px) {
  .packages_cards_div .container {
    width: 90% !important;
    height: auto;
    margin: 0px auto;
  }
  .packages_cards_div .package_cards_data {
    margin: 6rem 0px;
    width: 24rem;
    height: 30rem;
  }
  .package_cards_data:hover {
    cursor: pointer;
    color: white !important;
    height: 33rem;
    margin: 5rem 0;
    margin-top: 3rem;
  }

  .solutionpage_sketching_section .container {
    width: 85%;
    padding: 0rem !important;
  }
  .solutionpage_sketching_section .grid {
    gap: 5rem !important;
  }
  .solutionpage_fed_up_section .container {
    width: 85%;
    padding: 0rem !important;
  }
  .solutionpage_fed_up_section .grid {
    gap: 5rem !important;
  }
  .solutionpage_wisely_section .container {
    width: 85%;
    padding: 0rem !important;
  }
  .solutionpage_wisely_section .grid {
    gap: 5rem !important;
  }

  .solutionpage_package_datails_section .container {
    width: 85%;
    /* margin: 7rem auto; */
  }
  .grid {
    display: grid;
    gap: 5rem !important;
  }
  .our_portfolio_section .container {
    width: 85%;
  }
}

@media screen and (max-width: 1024px) {
  .solution_hero_section {
    width: 100%;
    height: 55rem !important;
    background: url(../Images/solution_page_mobile.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .solution_hero_section::before {
    content: "";
    width: 100%;
    height: inherit;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to right,
      rgba(84, 98, 186, 0.8),
      rgba(84, 98, 186, 0.7)
    );
  }

  .solution_hero_section .container {
    display: flex;
    justify-content: start;
  }

  .solution_hero_left {
    width: 80% !important;
    margin-top: 10rem !important;
  }

  /* sketching section */

  .solution_hero_left {
    width: 75% !important;
    margin-top: 6rem;
  }

  .solutionpage .rows {
    width: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .solutionpage_sketching_section {
    width: 100%;
    height: auto;
    /* background: red; */
  }

  .solutionpage_sketching_section .container {
    width: 90%;
    /* height: 60vh; */
    margin: 20px auto;
    padding: 2rem !important;
  }

  .solutionpage_sketching_section .grid-two-column {
    grid-template-columns: 1fr;
  }
  .solutionpage_sketch_image {
    width: 100%;
    order: 2;
  }
  .solutionpage_sketch_image img {
    width: 100%;
  }

  .solutionpage_sketch_right p {
    margin: 1rem 0rem;
  }

  /* end sketching section */

  /* fed_up section */

  .solutionpage_fed_up_section {
    width: 100%;
    height: auto;
  }

  .solutionpage_fed_up_section .container {
    width: 90%;
    /* height: 60vh; */
    margin: 7rem auto;
    padding: 2rem !important;
  }

.solutionpage_fed_up_section .grid{
  gap: 2rem !important;
}

  .solutionpage_fed_up_section .grid-two-column {
    grid-template-columns: 1fr;
  }
  .solutionpage_fed_up_right .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }

  .solutionpage_fed_up_right p {
    margin: 1rem 0rem;
  }

  /* end fed-up section */

  /* fed_up section */

  .solutionpage_wisely_section {
    width: 100%;
    height: auto;
    /* background: red; */
  }

  .solutionpage_wisely_section .container {
    width: 90%;
    /* height: 60vh; */
    margin: 7rem auto;
    padding: 2rem !important;
  }

  .solutionpage_wisely_section .grid-two-column {
    grid-template-columns: 1fr;
  }
  .solutionpage_wisely_image {
    width: 100%;
    order: 1;
  }
  .solutionpage_wisely_image img {
    width: 100%;
  }

  .solutionpage_wisely_right p {
    margin: 1rem 0rem;
  }

  /* end wisely section */

  /* package_deta section */

  .packages_cards_div .container {
    width: 95%;
    height: auto;
    margin: 0px auto;
  }

  .package_slider {
    width: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .package_slider::-webkit-scrollbar {
    overflow: hidden !important;
  }
  /* .packages_cards_div .container {
    width: 100% !important;
    height: auto;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
  } */

  .solutionpage_package_datails_section {
    width: 100%;
    height: auto;
    /* background: red; */
  }

  .solutionpage_package_datails_section .container {
    width: 90%;
    /* height: 60vh; */
    margin: 7rem auto;
    padding: 2rem !important;
  }

  .solutionpage_package_datails_section .grid-two-column {
    grid-template-columns: 1fr;
  }
  .solutionpage_package_datails_image {
    width: 100%;
    order: 1;
  }
  .solutionpage_package_datails_image img {
    width: 100%;
  }

  .photo_gallary {
    display: block;
  }
  /* end packages_details section */
}

@media only screen and (max-width: 1024px) {
  /* .packages_cards_div .container {
    width: 100%;
    height: auto;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
  } */
  .photo_gallary {
    display: block;
  }
}

/* 768 */
@media screen and (max-width: 768px) {
  .solution_hero_section {
    width: 100%;
    height: 55rem;
    background: url(../Images/solution_page_mobile.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .solution_hero_section::before {
    content: "";
    width: 100%;
    height: inherit;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to right,
      rgba(84, 98, 186, 0.8),
      rgba(84, 98, 186, 0.7)
    );
  }

  /* .solution_hero_section .container {
    width: 90%;
    display: flex;
    justify-content: start;
  } */

  .package_slider {
    width: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .package_slider::-webkit-scrollbar {
    overflow: hidden;
  }

  .solution_hero_left {
    width: 80% !important;
    margin-top: 10rem !important;
  }

  .solutionpage .rows {
    width: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .solutionpage_sketching_section {
    width: 100%;
    height: auto;
    /* background: red; */
  }

  .solutionpage_sketching_section .container {
    width: 90%;
    /* height: 60vh; */
    margin: 2rem auto;
    padding: 2rem !important;
  }

  .solutionpage_sketching_section .grid-two-column {
    grid-template-columns: 1fr;
  }
  .solutionpage_sketch_image {
    width: 100%;
    order: 2;
  }
  .solutionpage_sketch_image img {
    width: 100%;
  }

  /* fed_up section */

  .solutionpage_fed_up_section {
    width: 100%;
    height: auto;
  }

  .solutionpage_fed_up_section .container {
    width: 90%;
    /* height: 60vh; */
    margin: 7rem auto;
    padding: 2rem !important;
  }

  .solutionpage_fed_up_section .grid-two-column {
    grid-template-columns: 1fr;
  }
  .solutionpage_fed_up_right .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }

  /* end fed-up section */

  /* fed_up section */

  .solutionpage_wisely_section {
    width: 100%;
    height: auto;
    /* background: red; */
  }

  .solutionpage_wisely_section .container {
    width: 90%;
    /* height: 60vh; */
    margin: 7rem auto;
    padding: 2rem !important;
  }

  .solutionpage_wisely_section .grid-two-column {
    grid-template-columns: 1fr;
  }
  .solutionpage_wisely_image {
    width: 100%;
    order: 1;
  }
  .solutionpage_wisely_image img {
    width: 100%;
  }

  /* end wisely section */

  /* package_deta section */

  .solutionpage_package_datails_section {
    width: 100%;
    height: auto;
    /* background: red; */
  }

  .solutionpage_package_datails_section .container {
    width: 90%;
    /* height: 60vh; */
    margin: 7rem auto;
    padding: 2rem !important;
  }

  .solutionpage_package_datails_section .grid-two-column {
    grid-template-columns: 1fr;
  }
  .solutionpage_package_datails_image {
    width: 100%;
    order: 1;
  }
  .solutionpage_package_datails_image img {
    width: 100%;
  }

  .photo_gallary {
    display: block;
  }
  /* end packages_details section */
}

/* 576 */

@media screen and (max-width: 576px) {
  .solution_hero_section {
    width: 100%;
    height: 55rem !important;
    background: url(../Images/solution_page_mobile.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .solution_hero_section .container {
    width: 90%;
    display: flex;
    justify-content: start;
  }
  .solution_hero_left {
    width: 80% !important;
    margin-top: 10rem !important;
  }
}

@media screen and (max-width: 492px) {
  .solution_hero_section {
    width: 100%;
    height: 55rem !important;
    background: url(../Images/solution_page_mobile.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .solution_hero_section .container {
    width: 90%;
    display: flex;
    justify-content: start;
  }
}

@media screen and (max-width: 350px) {
  .solution_hero_section {
    width: 100%;
    height: 60rem !important;
    background: url(../Images/solution_page_mobile.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}


.our_portfolio_section .portfolio_right button {
height: 3rem ;
  margin-top: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 3rem;
    text-transform: uppercase;
    color: #5ea410;
    transition: all 0.5s linear;
}