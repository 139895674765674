html {
  font-size: 62.5%;
}

element.style{
  margin-left: 5rem !important ;
}

.offering_hero_section {
  width: 100%;
  height: 85vh;
  background: url(../Images/offering_hero.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 10% -20% !important;
  position: relative;
}
.offering_hero_section::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(84, 98, 186, 0.8),
    rgba(84, 98, 186, 0.7)
  );
}

.offering_hero_section .container {
  width: 90% !important;
  display: flex;
  justify-content: start;
  padding: 0 !important;
}

.offering_hero_left {
  width: 40% !important;
  margin-top: 14rem;
}

.offering_hero_left h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 2.7rem;
  line-height: 3.5rem;
  z-index: 99;
  opacity: 0.9;
  color: #ffffff;
}

.offering_hero_left h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.5rem !important;
  line-height: 6.5rem;
  opacity: 0.98;
  z-index: 999;

  color: #ffffff;
}

.offering_hero_left p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  /* or 150% */
  opacity: 0.98;
  word-spacing: 1px;
  z-index: 999;
  text-align: justify;
  color: #ededed;
}

/* css services and cards section */
.mobile_view_slider {
  display: none !important;
}
.services {
  width: 100%;
  height: 65rem;
  margin-top: 2%;
}

.services_heading {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services_heading h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 7.5rem;
  text-align: center;
  text-transform: capitalize;
  color: #5462ba;
}

.services_heading p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.8rem;
  text-align: center;
  color: #707070;
}

.carded {
  /* width: 32rem !important; */
  height: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  border: 2px solid #5462ba;
  /* background: green ; */
  padding: 2.5rem;
  transition: all 0.5s linear;
  cursor: pointer;
}

/* for active cards */
.carded_first {
  /* width: 32rem !important; */
  height: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  transition: all 0.5s linear;
  border: 2px solid #5462ba;
  transform: scale(1.03);
  cursor: pointer;
  background: #5462ba;
  color: white !important;
  padding: 2.5rem;
}

.carded_second {
  /* width: 32rem !important; */
  height: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  transition: all 0.5s linear;
  border: 2px solid #5462ba;
  transform: scale(1.03);
  cursor: pointer;
  background: #5462ba;
  color: white !important;
  padding: 2.5rem;
}

.carded_third {
  /* width: 32rem !important; */
  height: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  transition: all 0.5s linear;
  border: 2px solid #5462ba;
  transform: scale(1.03);
  cursor: pointer;
  background: #5462ba;
  color: white !important;
  padding: 2.5rem;
}

.carded_four {
  /* width: 32rem !important; */
  height: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  transition: all 0.5s linear;
  border: 2px solid #5462ba;
  transform: scale(1.03);
  cursor: pointer;
  background: #5462ba;
  color: white !important;
  padding: 2.5rem;
}

.carded_five {
  /* width: 28rem !important; */
  height: 34rem;
}

.carded_five {
  /* width: 32rem !important; */
  height: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  transition: all 0.5s linear;
  border: 2px solid #5462ba;
  transform: scale(1.03);
  cursor: pointer;
  background: #5462ba;
  color: white !important;
  padding: 2.5rem;
}

.carded_first img {
  filter: brightness(0) invert(1);
  width: 8rem;
  color: white;
}

.carded_second img {
  filter: brightness(0) invert(1);
  width: 8rem;
  color: white !important;
}

.carded_third img {
  filter: brightness(0) invert(1);
  width: 8rem;
  color: white;
}

.carded_four img {
  filter: brightness(0) invert(1);
  width: 8rem;
  color: white;
}

.carded_five img {
  filter: brightness(0) invert(1);
  width: 8rem;
  color: white;
}

.carded_first figure {
  /* width: 150px; */
  /* background: rebeccapurple; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carded_second figure {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carded_third figure {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carded_four figure {
  /* width: 150px; */
  /* background: rebeccapurple; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carded_five figure {
  /* width: 150px; */
  /* background: rebeccapurple; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carded_first h2 {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 5rem;

  color: #ffffff;
}

.carded_second h2 {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 5rem;

  color: #ffffff;
}

.carded_third h2 {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 5rem;

  color: #ffffff;
}

.carded_four h2 {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 5rem;

  color: #ffffff;
}

.carded_five h2 {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 5rem;

  color: #ffffff;
}
.carded_first p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: #ffffff;
}

.carded_second p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: #ffffff;
}

.carded_third p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: #ffffff;
}

.carded_four p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: #ffffff;
}

.carded_five p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: #ffffff;
}
.desktop_view_slider .mySwiper {
  width: 90% !important;
  margin-left: 3rem;
  margin: 0 auto !important;
}


.desktop_view_slider .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "next";
  width: 2rem !important;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  /* border: 1px solid #ededed; */
  background: #202e86;
  color: white;
  font-size: 1rem !important;
  position: fixed;
  right: -1rem;
  z-index: 9999;
  /* color: #202e86; */
  /* background: transparent; */
  outline: 2px solid #202e86;
}

.card_slider_main .swiper-slide {
  /* width: 34rem !important; */
}
.desktop_view_slider .swiper-button-prev:after,
.swiper-rtl .swiper-button-prev::before {
  content: "prev";
  width: 2rem !important;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  /* border: 1px solid #ededed; */
  background: #202e86;
  color: white;
  font-size: 1rem !important;
  position: fixed;
  left: -1.5rem;
  z-index: 11;
  /* background: transparent; */
  /* color: #202e86; */
  outline: 2px solid #202e86;
}

/* end for active cards */
.services .carded:hover {
  border: 2px solid #5462ba;
  transform: scale(1.03);
  cursor: pointer;
  background: #5462ba;
  color: white !important;
}

.carded:hover h2 {
  color: white;
}

.carded:hover p {
  color: white;
}
.mobile_view_slider .rows {
  gap: 6rem !important;
}
.carded:hover img {
  filter: brightness(0) invert(1);
  color: white;
}

.carded figure {
  /* width: 150px; */
  /* background: rebeccapurple; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carded h2 {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 5rem;

  color: #070a1d;
}

.carded p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: #707070;
}

.carded_third figure {
  width: 14rem !important;
}

.carded_first figure {
  width: 16rem !important;
}

.carded_second img {
  /* filter: invert(58%) sepia(9%) saturate(3339%) hue-rotate(195deg)
    brightness(94%) contrast(93%); */
  width: 9rem;
}

.carded figure img {
  width: 8rem;
}

/* for slider */
.card_slider_main {
  position: relative;
  width: 100%;
  height: 40rem;
}

.slider_cards {
  width: 78%;
  /* height: 50rem; */
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);
  gap: 4rem !important;
  /* overflow: hidden; */
}

.slick-next:before {
  display: none;
}

.slick-prev:before {
  display: none;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 !important;
}
.services .slick-slider {
  padding: 4rem !important;
  /* margin: 1rem; */
  /* margin-left: 2rem; */
}

/* css services and cards section end */

/* sketching section */

.service_sketching_section {
  width: 100%;
  height: auto;
  /* background: red; */
}

.service_sketching_section .container {
  width: 73%;
  /* height: 60vh; */
  margin: 0px auto;
}
.service_sketching_section .grid {
  display: grid;
  gap: 9rem !important;
  margin: 0 auto !important;
}

.service_sketching_section .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}
.service_sketch_image {
  width: 100%;
}
.service_sketch_image img {
  width: 100%;
}

.service_sketch_right {
  width: 100%;
  height: 100%;
  /* background: green; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.service_sketch_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 4.4rem;
  word-spacing: 1.6px;
  /* or 115% */

  letter-spacing: 0.04em;

  color: #070a1d;
}

.service_sketch_right p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  word-spacing: 1px;
  letter-spacing: 0.5px;
  text-align: justify;
  margin-top: 2rem;
  color: #707070;
}

.service_sketch_right .sketch_design {
  font-weight: 600;
  color: #5462ba;
}

/* end sketching section */

/* digital section */

.service_digital_section {
  width: 100%;
  height: auto;
  /* background: red; */
}

.service_digital_section .container {
  width: 73%;
  margin: 5rem auto;
}
.grid {
  display: grid;
  gap: 9rem !important;
}

.service_digital_section .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}
.service_digital_image {
  width: 100%;
}
.service_digital_image img {
  width: 100%;
}

.service_digital_right {
  width: 100%;
  height: 100%;
  /* background: green; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.service_digital_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 4.4rem;
  word-spacing: 1.7px;
  left: 0.5px;
  /* or 115% */

  letter-spacing: 0.04em;

  color: #070a1d;
}

.service_digital_right p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.8rem;
  word-spacing: 1px;
  letter-spacing: 0.5px;
  text-align: justify;
  margin-top: 20px;
  color: #707070;
}

.service_digital_right .digital_design {
  font-weight: 600;
  color: #5462ba;
}

/* end digital section */

/* software section */

.service_software_section {
  width: 100%;
  height: auto;
  /* background: red; */
}

.service_software_section .container {
  width: 73%;
  /* height: 60vh; */
  margin: 5rem auto;
}
.grid {
  display: grid;
  gap: 9rem !important;
}

.service_software_section .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}
.service_software_image {
  width: 100%;
}
.service_software_image img {
  width: 100%;
}

.service_software_right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.service_software_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 4.4rem;
  word-spacing: 1.7px;
  left: 0.5px;
  /* or 115% */
  letter-spacing: 0.04em;
  color: #070a1d;
}

.service_software_right p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  word-spacing: 1px;
  letter-spacing: 0.5px;
  text-align: justify;
  margin-top: 20px;
  color: #707070;
}

.service_software_right .software_design {
  font-weight: 600;
  color: #5462ba;
}

/* end software section */

/* button section start */
.button_section {
  width: 100%;
  height: auto;
  /* background: red; */
}

.button_section .btn {
  width: 22rem;
  height: 5rem;
  background: #202e86;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 3.5rem;
  /* or 117% */
  text-align: center;
  color: #ffffff;
}
/* button section end */

/* start portfolio section */

.our_portfolio_section {
  width: 100%;
  height: auto;
}
.our_portfolio_section .container {
  width: 75% !important;
}

.portfolio_heading h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3rem;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #5462ba;
}
.portfolio_heading p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;

  color: #000000;
}

.our_portfolio_section .servies_img1 {
  margin-left: 0 !important;
}

.portfolio_img1 {
  margin-top: 15rem;
}

.our_portfolio_section .portfolio_right h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 4.5rem;
  color: #000000;
}

.our_portfolio_section .portfolio_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3.7rem;
  line-height: 6rem;
  /* or 136% */

  text-transform: capitalize;

  color: #3f3f3f;
}

.our_portfolio_section .portfolio_right p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem !important;
  line-height: 2.7rem !important;
  word-spacing: 1px;
  text-align: justify;
  color: #707070;
  margin-top: 10px;
}

.our_portfolio_section .portfolio_right button {
  margin-top: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
  text-transform: uppercase;
  color: #5ea410;
  transition: all 0.5s linear;
}

.our_portfolio_section .portfolio_right button:hover {
  border-bottom: 1px solid #5ea410;
}

.our_portfolio_section .container {
  width: 90%;
}

.our_portfolio_section .grid {
  display: grid;
  gap: 1rem;
}
.our_portfolio_section .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}

.portfolio_left_section {
  width: 50%;
  height: auto;
  display: flex;
}

/* for mobile view */
.photo_gallary {
  display: none;
}

/* ***************************************************992********************** */

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: px) {
  .slider_cards {
    width: 87%;
    /* height: 50rem; */
    position: absolute;
    left: 50%;
    top: 53%;
    transform: translate(-50%, -50%);
    gap: 4rem !important;
    /* overflow: hidden; */
  }
  .service_sketching_section .container {
    width: 85%;
    /* height: 60vh; */
    margin: 0px auto;
  }
  .service_digital_section .container {
    width: 85vw !important;
    margin: 5rem auto;
  }
  .service_software_section .container {
    width: 85vw !important;
    /* height: 60vh; */
    margin: 5rem auto;
  }
  .our_portfolio_section .container {
    width: 85vw !important;
  }
  .loaction_phone_message .container-fluid {
    width: 79% !important;
  }
  footer .container-fluid {
    width: 79% !important;
  }
}

@media only screen and (max-width: px) {
  .slider_cards {
    width: 90% !important;
    /* height: 50rem; */
    position: absolute;
    left: 50%;
    top: 53%;
    transform: translate(-50%, -50%);
    gap: 4rem !important;
    /* overflow: hidden; */
  }
  .service_sketching_section .container {
    width: 88%;
    /* height: 60vh; */
    margin: 0px auto;
  }
  .service_digital_section .container {
    width: 88%;
    margin: 3rem auto;
  }
  .service_software_section .container {
    width: 88%;
    /* height: 60vh; */
    margin: 3rem auto;
  }
  .our_portfolio_section .container {
    width: 88% !important;
  }
  .loaction_phone_message .container-fluid {
    width: 83% !important;
  }
  footer .container-fluid {
    width: 83% !important;
  }
}

@media only screen and (max-width: 1024px) {
  .loaction_phone_message .container-fluid {
    width: 100% !important;
  }
  footer .container-fluid {
    width: 90vw !important;
  }
  .carded {
    width: 28rem !important;
    height: 34rem !important;
  }

  .carded_first {
    width: 28rem !important;
    height: 34rem !important;
  }
  .carded_second {
    width: 28rem !important;
    height: 34rem !important;
  }
  .carded_third {
    width: 28rem !important;
    height: 34rem !important;
  }

  .carded_four {
    width: 28rem !important;
    height: 34rem !important;
  }

  .carded_five {
    width: 28rem !important;
    height: 34rem !important;
  }

  .offering_hero_section {
    width: 100%;
    height: 55rem !important;
  }
  .offering_hero_section::before {
    content: "";
    width: 100%;
    height: inherit;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to right,
      rgba(84, 98, 186, 0.8),
      rgba(84, 98, 186, 0.7)
    );
  }

  .offering_hero_section .container {
    width: 90%;
    display: flex;
    justify-content: start;
  }

  .offering_hero_left {
    width: 80% !important;
    margin-top: 10rem !important;
  }

  .offering_hero_left h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    z-index: 99;
    opacity: 0.9;
    color: #ffffff;
  }

  /* services slider section */

  .desktop_view_slider {
    display: none !important;
  }

  .mobile_view_slider {
    display: block !important;
  }

  .slider_cards {
    width: 100% !important;
    gap: 10px;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .slider_cards::-webkit-scrollbar {
    overflow: hidden;
  }
  .slick-next:before {
    display: none !important;
  }

  .slick-prev:before {
    display: none !important;
  }
  .services {
    width: 100%;
    height: auto;
  }

  .services_heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* sketching section */
  .service_sketching_section .container {
    width: 90%;
    height: auto;
    padding: 2rem !important;
  }
  .service_sketching_section .grid-two-column {
    grid-template-columns: 1fr;
    gap: 2rem !important;
  }
  .service_sketch_right {
    width: 100%;
    height: 100%;
    /* background: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .service_sketching_section .service_sketch_image {
    order: 1;
  }

  .service_sketch_right .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }

  .service_sketch_right p {
    margin-top: 1rem;
  }

  .service_digital_right p {
    margin-top: 1rem;
  }

  .service_software_right p {
    margin-top: 1rem;
  }
  /* digital section */
  .service_digital_section .grid-two-column {
    grid-template-columns: 1fr;
    gap: 2rem !important;
  }
  .service_digital_section .container {
    width: 90%;
    padding: 2rem !important;
  }
  .service_digital_right {
    /* background: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  /* end digital section */

  .service_software_section .grid-two-column {
    grid-template-columns: 1fr;
    gap: 2rem !important;
  }
  .service_software_section .container {
    width: 90%;
    padding: 2rem !important;
  }
  .service_software_image {
    width: 100%;
    height: 100%;
    /* background: green; */
    display: flex;
    flex-direction: column;
    order: 2;
  }

  .service_software_image img {
    width: 100%;
    height: 100%;
  }

  .our_portfolio_section .container {
    width: 90% !important;
    padding: 0rem !important;
    /* background: red; */
  }
  .our_portfolio_section .portfolio_right_main {
    width: 100%;
    /* background-color: royalblue; */
  }

  
  .photo_gallary .container {
    width: 80% !important;
    padding: 1rem;
  }
  .photo_gallary_h1 {
    font-weight: 700 !important;
    font-size: 1.5rem;
    font-family: "poppins";
    font-style: normal;
    margin: 1rem 0rem;
  }
  .mobile_portfolio_gallary_section {
    width: 100%;
  }
  .mobile_portfolio_gallary_section img {
    width: 100% !important;
    height: auto !important;
  }

  .mobile_portfolio_gallary_section {
    width: 100%;
    display: flex;
    gap: 3rem;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .mobile_portfolio_gallary_section::-webkit-scrollbar {
    overflow: hidden;
  }
  .portfolio_left_section {
    display: none;
  }
}

/* media queries */

@media only screen and (max-width: 768px) {
  .offering_hero_section {
    width: 100%;
    height: 55rem;
  }

  .offering_hero_section .container {
    width: 90%;
    padding: 0rem !important;
  }

  .offering_hero_left {
    width: 80% !important;
    /* margin-top: 15rem; */
  }

  /* services slider section */
  .desktop_view_slider {
    display: none !important;
  }
  .slider_cards {
    width: 100% !important;
    gap: 1rem;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .slider_cards::-webkit-scrollbar {
    overflow: hidden;
  }
  .slick-next:before {
    display: none !important;
  }

  .slick-prev:before {
    display: none !important;
  }
  .services {
    width: 100%;
    height: auto;
  }

  .services_heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* sketching section */
  .service_sketching_section .container {
    width: 90%;
    height: auto;
    padding: 2rem !important;
  }
  .service_sketching_section .grid-two-column {
    grid-template-columns: 1fr;
    gap: 2rem !important;
  }
  .service_sketch_right {
    width: 100%;
    height: 100%;
    /* background: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .service_sketching_section .service_sketch_image {
    order: 1;
  }

  .service_sketch_right .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }

  /* digital section */
  .service_digital_section .grid-two-column {
    grid-template-columns: 1fr;
    gap: 2rem !important;
  }
  .service_digital_section .container {
    width: 90%;
    padding: 2rem !important;
  }
  .service_digital_right {
    /* background: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  /* end digital section */

  .service_software_section .grid-two-column {
    grid-template-columns: 1fr;
    gap: 2rem !important;
  }
  .service_software_section .container {
    width: 90%;
    padding: 2rem !important;
  }
  .service_software_image {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    order: 2;
  }

  .our_portfolio_section .container {
    width: 90% !important;
    padding: 0 !important;
  }
  .our_portfolio_section .portfolio_right_main {
    width: 100%;
    /* background-color: royalblue; */
  }

  .button_section .btn {
    width: 22rem;
    height: 5rem;
    background: #202e86;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    border-radius: 10px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 3.5rem;
    /* or 117% */
    text-align: center;
    color: #ffffff;
  }

  /********************* portofolio section mbile view ***************/

  .photo_gallary .container {
    width: 90% !important;
    padding: 1rem;
  }
  .photo_gallary_h1 {
    font-weight: 700 !important;
    font-size: 1.5rem;
    font-family: "poppins";
    font-style: normal;
    margin: 1rem 0rem;
  }
  .mobile_portfolio_gallary_section {
    width: 100%;
    /* padding: 0rem 4rem; */
  }
  .mobile_portfolio_gallary_section img {
    width: 100% !important;
    height: auto !important;
  }

  /********************* portofolio section mbile view ***************/
}

/* 460 */

@media only screen and (max-width: 576px) {
  .offering_hero_section {
    width: 100%;
    height: 55rem;
  }
  .offering_hero_section .container {
    width: 90%;
    padding: 0 !important;
  }

  .service_software_section .container {
    width: 90%;
    padding: 2rem !important;
  }

  .desktop_view_slider {
    display: none !important;
  }
  .our_portfolio_section .container {
    width: 90% !important;
    padding: 2rem !important;
  }

  .service_digital_section .container {
    width: 90%;
    padding:2rem !important;
  }

  .service_sketching_section .container {
    width: 90%;
    padding: 2rem !important;
  }

  .button_section .btn {
    width: 22rem;
    height: 5rem;
    background: #202e86;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    border-radius: 10px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 3.5rem;
    /* or 117% */
    text-align: center;
    color: #ffffff;
  }

  /********************* portofolio section mbile view ***************/

  .photo_gallary .container {
    width: 90% !important;
    padding: 1rem;
  }
  .photo_gallary_h1 {
    font-weight: 700 !important;
    font-size: 1.5rem;
    font-family: "poppins";
    font-style: normal;
    margin: 1rem 0rem;
  }
  .mobile_portfolio_gallary_section {
    width: 100%;
    /* padding: 0rem 4rem; */
  }
  .mobile_portfolio_gallary_section img {
    width: 100% !important;
    height: auto !important;
  }

  /********************* portofolio section mbile view ***************/
}

@media only screen and (max-width: 492px) {
  .offering_hero_section {
    width: 100%;
    height: 55rem;
  }

  .offering_hero_section .container {
    width: 90%;
    padding: 2rem !important;
  }
  .mobile_portfolio_gallary_section img {
    width: 90vw !important ;
    height: 100rem;
  }

  .desktop_view_slider {
    display: none !important;
  }

  /********************* portofolio section mbile view ***************/

  .photo_gallary .container {
    width: 90% !important;
    padding: 1rem;
  }
  .photo_gallary_h1 {
    font-weight: 700 !important;
    font-size: 1.5rem;
    font-family: "poppins";
    font-style: normal;
    margin: 1rem 0rem;
  }
  .mobile_portfolio_gallary_section {
    width: 100%;
    /* padding: 0rem 4rem; */
  }
  .mobile_portfolio_gallary_section img {
    width: 100% !important;
    height: auto !important;
  }

  /********************* portofolio section mbile view ***************/
}

@media only screen and (max-width: 350px) {
  .offering_hero_section .container {
    width: 90%;
    padding: 0 !important;
  }

  .offerring_hero_section {
    width: 100%;
    height: 60rem !important;
    background: url(../Images/solution_page_mobile.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .desktop_view_slider {
    display: none !important;
  }

  .button_section .btn {
    width: 22rem;
    height: 5rem;
    background: #202e86;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    border-radius: 10px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 3.5rem;
    /* or 117% */
    text-align: center;
    color: #ffffff;
  }
  .mobile_portfolio_gallary_section img {
    width: 90vw !important ;
    height: 100rem;
  }

  /********************* portofolio section mbile view ***************/

  .photo_gallary .container {
    width: 90% !important;
    padding: 1rem;
  }
  .photo_gallary_h1 {
    font-weight: 700 !important;
    font-size: 1.5rem;
    font-family: "poppins";
    font-style: normal;
    margin: 1rem 0rem;
  }
  .mobile_portfolio_gallary_section {
    width: 100%;
    /* padding: 0rem 4rem; */
  }
  .mobile_portfolio_gallary_section img {
    width: 100% !important;
    height: auto !important;
  }

  /********************* portofolio section mbile view ***************/
}

/* 1800 */
