html {
  font-size: 62.5%;
}

.products {
  width: 100%;
  height: auto;
  /* background: red; */
}

.products .container {
  width: 80%;
  height: auto;
  /* background: rebeccapurple; */
}

.products .rows {
  gap: 6rem !important;
}

.products_heading {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.products_heading h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 7.5rem;
  text-align: center;
  text-transform: capitalize;

  color: #3f3f3f;
}

.products_heading p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.7rem;
  text-align: center;
  color: #707070;
}
.carde {
  width: 28rem;

  height: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  border: 2px solid #5462ba;
  /* background: green ; */
  padding: 2.5rem;
  transition: all 0.5s linear;
  cursor: pointer;
}

.mobile_slider {
  width: 28rem !important;
  height: auto !important;
}

/* """"""""""""""for active cards""""""""""""""" */

.carde_first {
  width: 28rem;

  height: 35rem;
  background: #5462ba;
  color: white !important;
  transform: scale(1.03);
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  border: 2px solid #5462ba;
  /* background: green ; */
  padding: 2.5rem;
  transition: all 0.5s linear;
  cursor: pointer;
}
.carde_first img {
  filter: brightness(0) invert(1);
  color: white;
}

.carde_first h2 {
  transition: all 0.5s linear;
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 5rem;
  color: white !important;
}

.carde_first p {
  transition: all 0.5s linear;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: white !important;
}

.carde_first figure {
  width: 12rem !important;
  height: 12rem !important;
  /* background: rebeccapurple; */
  display: flex;
  justify-content: center;
  align-items: center;
}



.carde_second {
  width: 28rem;

  height: 35rem;
  background: #5462ba;
  color: white !important;
  transform: scale(1.03);
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  border: 2px solid #5462ba;
  /* background: green ; */
  padding: 2.5rem;
  transition: all 0.5s linear;
  cursor: pointer;
}
.carde_second img {
  filter: brightness(0) invert(1);
  color: white;
}

.carde_second h2 {
  transition: all 0.5s linear;
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 5rem;
  color: white !important;
}

.carde_second p {
  transition: all 0.5s linear;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: white !important;
}

.carde_second figure {
  width: 7rem !important;
  height: 7rem !important;
  /* background: rebeccapurple; */
  display: flex;
  justify-content: center;
  align-items: center;
}


.carde_third {
  width: 28rem;

  height: 35rem;
  background: #5462ba;
  color: white !important;
  transform: scale(1.03);
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  border: 2px solid #5462ba;
  /* background: green ; */
  padding: 2.5rem;
  transition: all 0.5s linear;
  cursor: pointer;
}
.carde_third img {
  filter: brightness(0) invert(1);
  color: white;
}

.carde_third h2 {
  transition: all 0.5s linear;
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 5rem;
  color: white;
}

.carde_third p {
  transition: all 0.5s linear;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: white;
}

.carde_third figure {
  width: 10rem !important;
  height: 10rem !important;
  /* background: rebeccapurple; */
  display: flex;
  justify-content: center;
  align-items: center;
}


/* """"""""""""""for active cards""""""""""""""" */

.carde:hover {
  background: #5462ba;
  color: white !important;
  transform: scale(1.03);
  color: white;
}
.carde:hover h2 {
  color: white;
}

.carde:hover p {
  color: white;
}

.carde:hover img {
  filter: brightness(0) invert(1);
  color: white;
}

.carde_second img {
  /* filter: invert(58%) sepia(9%) saturate(3339%) hue-rotate(195deg)
    brightness(94%) contrast(93%); */
  /* color: #5462BA; */
}

.carde figure {
  width: 12rem !important;
  height: 12rem !important;
  /* background: rebeccapurple; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.carde figure img {
  /* width: 50px; */
}

.carde_first figure {
  width: 12rem;
  /* background: red; */
}
.carde_third figure {
  width: 12.8rem !important;
}

.carde h2 {
  transition: all 0.5s linear;
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 5rem;

  color: #070a1d;
}



.carde p {
  transition: all 0.5s linear;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: #707070;
}

.carde_second figure img {
  width: 12.9rem !important;
}

/* Media queries */

@media screen and (max-width: 1024px) {
  .products {
    width: 100%;
    height: auto;
    margin: 4rem 0rem;
    /* background: red; */
  }
  .products .container {
    width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  .products {
    width: 100%;
    height: auto;
    margin: 4rem 0rem;
    /* background: red; */
  }
  .products .container {
    width: 100% !important;
  }

  .products .rows {
    width: 100%;
    display: flex;
    gap: 2rem;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .products .rows::-webkit-scrollbar {
    overflow: hidden;
  }
}

/* media queries */
