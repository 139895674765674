html{
  font-size: 62.5%;
}

.project__div {
  height: 100%;
  width: 100%;
}

.main_project_sections{
  width:  100% !important;
  height: auto !important;
  /* background: #000; */

}

.main_project_sections .container-fluid{
  width: 95vw !important;
}
.main_project_sections .grid{
  display: grid;
  gap: 1.5rem !important;
  margin: 3rem auto !important;
}

.main_project_sections .grid-four-column{
  grid-template-columns: repeat(4, 1fr);
}

.main_projects_section{
  width: 100% !important;
}

.project__image {
  height: 100%;
  position: relative;
}
.project__image img{
  width: 100%; 
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
}

.project__image::before {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, #070A1D 100%);
}


.projects__title {

  /* width: 23rem; */
  height: 5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  /* line-height: 2rem; */
  color: #ffffff;
  margin-top: -8rem;
  margin-left: 2.5rem;
  z-index: 999;
  opacity: .9;
}

.projects__learn {
  /* width: 20.5rem; */
  height: 2rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #5EA410;
  margin-left: 2.5rem;
    opacity: .9;
}

.mbile_view{
  display: none !important;
}


.projects_mobile_view{
  width: 100%;
  height: 100% !important;
  display: none !important;
}

.projects_mobile_view img{
  width: 100% !important;
  height: 100% !important;
}
@media screen and (max-width: 1200px) {
  .projects__title {

  /* width: 23rem; */
  height: 5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  /* line-height: 2rem; */
  color: #ffffff;
  margin-top: -8rem;
  margin-left: 2.5rem;
  z-index: 999;
  opacity: .9;
}
}

@media only screen and (max-width: 1024px) {
.main_project_sections{
  width:  100% !important;
  height: auto !important;

}
.project_desktop_view{
  display: none !important;
}

.projects_mobile_view{
  display: block !important;
}
.main_projects_section{
  width: 100%;
  padding: 3rem;
}

.project__image {
  height: auto;
  width: 100%;  
}
.project__image img{
  width: 100%;
  height: 100%;
  position: relative;
}
  .projects__title {

  /* width: 23rem; */
  height: 5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  /* line-height: 2rem; */
  color: #ffffff;
  margin-top: -10rem;
  margin-left: 2.5rem;
  z-index: 999 !important;
  opacity: .9;
  position: fixed;
}

.projects__learn {
    width: 100%;
    height: 2rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: #5EA410;
    margin-left: 2.5rem;
    top: -4rem;
    position: relative;
    z-index: 9999 !important;
    opacity: .9;
}
.projects_mobile_view .project__image{
  height: 100%;
  position: relative;
}
.projects_mobile_view  .project__image::before {
  content: "";
  top: 0;
  z-index: 1;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* position: absolute; */
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, #070A1D 100%);
}  

.main_projects_section{
  width: 100% ;
  height: 100%;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
}
.main_project_section::-webkit-scrollbar{
  overflow: hidden;
}
.projects_mobile_view{
  width: 100%;
}


.projects_mobile_view{
  width: 100%;
  height: 100% !important;
}

.projects_mobile_view .container{
  width: 90vw !important;
  /* background-color: rebeccapurple; */
}

.projects_mobile_view .project__div{
  width: 100% !important;
  height: auto;
}

.projects_mobile_view .project__div img{
  width: 90vw !important;
  height: auto;
}



.projects_mobile_view img{
  width: 100% !important;
  height: 100% !important;
}


}


@media (max-width: 768px) {
.main_project_sections{
  width:  100% !important;
  height: auto !important;

}
.main_projects_section{
  width: 100%;
  padding: 3rem;
}

.project__image {
  height: auto;
  width: 100%;
  /* background-size: 100%; */
}
.project__image img{
  width: 90vw !important;
  height: auto;
}


.projects__learn {
    width: 100%;
    height: 2rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: #5EA410;
    margin-left: 2.5rem;
    top: -4rem;
    position: relative;
    z-index: 9999 !important;
    opacity: .9;
}

.project__image::before {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, #070A1D 100%);
}                                    

.main_projects_section{
  width: 100% ;
  height: auto;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
}
.main_project_section::-webkit-scrollbar{
  overflow: hidden;
}

.main_projects_section{
  display: none !important;
}

.projects_mobile_view{
  width: 100%;
  height: 100% !important;
  display: block !important;
}
}


@media screen and (max-width: 492px) {
  .mobile_projects_learn{
    width: 100%;
    background-color: rebeccapurple;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
  } 
}