/* sketching section */
html {
  font-size: 62.5% !important;
}

  /* .blog {
  position:relative;
  }
.line {   
  height: 120rem;
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 50%;
    gap: 3rem !important;
    border-left: 7px dashed #5462BA80;
  } */


.blogspage_section {
  width: 100%;
  height: auto;
  /* background: red; */
  /* position: relative; */
  /* margin-top: 30px; */
}

.blogspage_section .container {
  width: 75%;
  /* height: 60vh; */
  margin: 2rem auto;
}
.blogs_heading {
  width: 82%;
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: red; */
}

.blogs_heading h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3.4rem;
  line-height: 7rem;
  color: #070a1d;
}

.blogs_heading p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  text-align: center;
  color: #707070;
}
.blogs_heading .blogs {
  font-weight: bold;
  color: #5462ba;
}
.blogspage_section .grid {
  display: grid;
  gap: 10rem !important;
  margin-top: 5rem;
}

.blogspage_section .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}
.blogspage_image {
  width: 100%;
}
.blogspage_image img {
  width: 100%;
}

.blogspage_right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.blogspage_right .links {
  border-bottom: 0.2rem solid #707070;
}
.blogspage_right .read_more {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 3.5rem;
  text-transform: uppercase;
  color: #5ea410;
  height: 3rem;
  transition: all 0.5s linear;
}

.blogspage_right .read_more:hover {
  border-bottom: 2px solid #5ea410;
}
.blogspage_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.6rem;
  word-spacing: 0.2rem;
  letter-spacing: 0.04em;
  color: #070a1d;
}

.blogspage_right p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  word-spacing: 0.1rem;
  text-align: justify;
  margin-top: 2rem;
  color: #707070;
}

.blogspage_right .sketch_design {
  font-weight: 600;
  color: #5462ba;
}

/* for mobile view */

.blogspage_image small {
  font-size: 1.6rem;
}

.blogspage_image .sketch_design {
  font-weight: 600;
  color: #5462ba;
}
.blogspage_image h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.6rem;
  margin: 1rem 0;
  word-spacing: 0.2rem;
  /* or 115% */

  letter-spacing: 0.04em;

  color: #070a1d;
}

.mobile_view_heading {
  display: none;
}

/* for vertacal line */

/* end sketching section */

/* media queries */

/* another section */

.blogspage_section1 {
  width: 100%;
  height: auto;
  /* background: red; */
  /* position: relative; */
  /* margin-top: 30px; */
}

.blogspage_section1 .container {
  width: 75%;
  /* height: 60vh; */
  margin: 6rem auto;
}
.blogs_heading {
  width: 82%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: red; */
}

.blogs_heading1 h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3.4rem;
  color: #070a1d;
}

.blogs_heading1 p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  text-align: center;
  color: #707070;
}
.blogs_heading .blogs {
  font-weight: bold;
  color: #5462ba;
}
.grid {
  display: grid;
  gap: 10rem !important;
}

.blogspage_section1 .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}
.blogspage_image1 {
  width: 100%;
  margin: 0px !important ;
}
.blogspage_image1 img {
  width: 100%;
}

.blogspage_right1 {
  width: 100%;
  height: 100%;
  /* background: green; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.blogspage_right1 .links {
  border-bottom: 2px solid #707070;
}
.blogspage_right1 .read_more {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  text-transform: uppercase;
  color: #5ea410;
  height: 3rem;
  transition: all 0.5s linear;
}
.blogspage_right1 .read_more:hover {
  border-bottom: 2px solid #5ea410;
}
.blogspage_right2 .read_more:hover {
  border-bottom: 2px solid #5ea410;
}
.blogspage_right1 h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.6rem;
  letter-spacing: 0.04em;
  color: #070a1d;
}

.blogspage_right1 p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  letter-spacing: 0.5px;
  text-align: justify;
  margin-top: 2rem;
  color: #707070;
}

.blogspage_right1 .sketch_design {
  font-weight: 600;
  color: #5462ba;
}

/* for vertacal line */

/* end sketching section */

/* media queries */

/* 1200px */

/* another section */

.blogspage_section2 {
  width: 100%;
  height: auto;
  /* background: red; */
  /* position: relative; */
  /* margin-top: 30px; */
}

.blogspage_section2 .container {
  width: 75%;
  /* height: 60vh; */
  margin: 6rem auto;
}
.blogs_heading2 {
  width: 82%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: red; */
}

.blogs_heading2 h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3.4rem;
  color: #070a1d;
}

.blogs_heading2 p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  text-align: center;

  color: #707070;
}
.blogs_heading2 .blogs {
  font-weight: bold;
  color: #5462ba;
}
.grid {
  display: grid;
  gap: 10rem !important;
}

.blogspage_section2 .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}
.blogspage_image2 {
  width: 100%;
}
.blogspage_image2 img {
  width: 100%;
}

.blogspage_right2 {
  width: 100%;
  height: 100%;
  /* background: green; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.blogspage_right2 .links {
  border-bottom: 0.2rem solid #707070;
}
.blogspage_right2 .read_more {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  text-transform: uppercase;
  color: #5ea410;
  height: 3rem;
  margin-top: 1rem;
  transition: all 0.5s linear;
}
.blogspage_right .read_more:hover {
  border-bottom: 2px solid #5ea410;
}
.blogspage_right2 h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.6rem;
  letter-spacing: 0.04em;

  color: #070a1d;
}

.blogspage_right2 p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  letter-spacing: 0.5px;
  text-align: justify;
  margin-top: 2rem;
  color: #707070;
}

.blogspage_right2 .sketch_design {
  font-weight: 600;
  color: #5462ba;
}

/* for vertacal line */

/* end sketching section */

/* media queries */

@media screen and (max-width: px) {
  .blogspage_section .container {
    width: 85vw;
    height: auto;
    padding: 0rem !important;
    margin: 6rem auto;
  }
  .blogspage_section1 .container {
    width: 85vw;
    height: auto;
    padding: 0rem !important;
    margin: 6rem auto;
  }
  .blogspage_section2 .container {
    width: 85vw;
    height: auto;
    padding: 0rem !important;
    margin: 6rem auto;
  }
}

@media screen and (max-width: 1024px) {
  .blogspage_section {
    width: 100%;
    height: auto;
  }
  .blogspage_section .grid {
    gap: 0rem !important;
  }
  .blogspage_section .grid-two-column {
    grid-template-columns: 1fr;
  }
  .blogspage_section .container {
    width: 90%;
    height: auto;
    padding: 2rem !important;
    margin: 6rem auto;
  }
  .blogs_heading {
    width: 100%;
  }

  .blogspage_right .sketch_design {
    order: 2;
    font-weight: 600;
    color: #5462ba;
  }
  .blogspage_image {
    width: 100%;
    height: 100%;
    /* position: relative; */
  }
  .blogspage_image img {
    width: 100%;
    height: 100%;
  }

  .grid {
    display: grid;
    gap: 0px !important;
  }
  .mobile_view_heading {
    display: block !important;
  }

  .blogspage_right .semi_heading_one,
  .semi_heading_two,
  .semi_heading_three {
    display: none;
  }

  .blogspage_image .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }

  .blogspage_image1 .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }
  .blogspage_image1 h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2.6rem;
    word-spacing: 0.2rem;
    letter-spacing: 0.04em;
    color: #070a1d;
  }

  .blogspage_image2 .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }
  .blogspage_image2 h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2.6rem;
    word-spacing: 0.2rem;
    /* or 115% */

    letter-spacing: 0.04em;

    color: #070a1d;
  }
  .blogspage_image1 small {
    font-size: 1rem;
  }
  .blogspage_image2 small {
    font-size: 1rem;
  }

  .blogspage_section1 {
    width: 100%;
    height: auto;
  }
  .blogspage_section1 .grid {
    gap: 0rem !important;
  }
  .blogspage_section1 .grid-two-column {
    grid-template-columns: 1fr !important;
  }
  .blogspage_section1 .container {
    width: 90% !important;
    height: auto;
    padding: 2rem !important;
    margin: 6rem auto;
  }
  .blogs_heading {
    width: 100%;
  }
  .blogspage_right1 {
    order: 1;
  }
  .blogspage_right1 .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }
  .blogspage_image1 {
    width: 100%;
    height: 100%;
    margin-top: 7rem;
    position: relative;
  }
  .blogspage_image1 img {
    width: 100%;
    height: 100%;
  }

  .grid {
    display: grid;
  }

  .blogspage_section2 {
    width: 100%;
    height: auto;
  }
    .blogspage_section2 .grid{
    gap: 0rem !important;
  }
  .blogspage_section2 .grid-two-column {
    grid-template-columns: 1fr !important;
  }
  .blogspage_section2 .container {
    width: 90% !important;
    padding: 2rem !important;
    margin: 6rem auto;
  }
  .blogs_heading {
    width: 100%;
  }

  .blogspage_right2 .sketch_design {
    order: 2;
    font-weight: 600;
    color: #5462ba;
  }
  .blogspage_image2 {
    width: 100%;
    /* margin-top: 70px; */
  }
  .blogspage_image2 img {
    width: 100%;
  }

  .grid {
    display: grid;
    gap: 1rem !important;
  }

  .blogspage_image1 h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3.6rem;
    margin: 1rem 0;
    letter-spacing: 0.04em;
    color: #070a1d;
  }

  .blogspage_image2 h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3.6rem;
    margin: 1rem 0;
    letter-spacing: 0.04em;
    color: #070a1d;
  }
}

@media screen and (max-width: 768px) {
  .blogspage_section {
    width: 100%;
    height: auto;
  }
  .blogspage_section .grid-two-column {
    grid-template-columns: 1fr;
  }
  .blogspage_section .container {
    width: 90%;
    height: auto;
    padding: 2rem !important;
    margin: 6rem auto;
  }
  .blogs_heading {
    width: 100%;
  }

  .blogspage_right .sketch_design {
    order: 2;
    font-weight: 600;
    color: #5462ba;
  }
  .blogspage_image {
    width: 100%;
    height: 100%;
    /* position: relative; */
  }
  .blogspage_image img {
    width: 100%;
    height: 100%;
  }

  .grid {
    display: grid;
    gap: 0px !important;
  }
  .mobile_view_heading {
    display: block !important;
  }

  .blogspage_right .semi_heading_one,
  .semi_heading_two,
  .semi_heading_three {
    display: none;
  }

  .blogspage_image .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }

  .blogspage_image1 .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }
  .blogspage_image2 .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }
  .blogspage_image1 small {
    font-size: 1rem;
  }
  .blogspage_image2 small {
    font-size: 1rem;
  }

  .blogspage_section2 {
    width: 100%;
    height: auto;
  }
  .blogspage_section2 .grid-two-column {
    grid-template-columns: 1fr;
  }
  .blogspage_section2 .container {
    width: 100%;
    padding: 2rem !important;
    margin: 2rem auto;
  }
  .blogs_heading {
    width: 100%;
  }

  .blogspage_right2 .sketch_design {
    order: 2;
    font-weight: 600;
    color: #5462ba;
  }
  .blogspage_image2 {
    width: 100%;
    /* margin-top: 70px; */
  }
  .blogspage_image2 img {
    width: 100%;
  }

  .blogs_heading p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.7rem;
    text-align: center;
    color: #707070;
  }
  .blogspage_right2 {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: flex-start;
  }
  .grid {
    display: grid;
    gap: 1rem !important;
  }
  .blogspage_right2 h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3rem;
    letter-spacing: 0.04em;

    color: #070a1d;
  }

  .blogspage_right2 p {
    font-size: 1.8rem;
    font-weight: 400;
    font-family: "poppins";
    font-style: normal;
    line-height: 3rem;
  }
  .blogspage_section1 {
    width: 100%;
    height: auto;
  }
  .blogspage_section1 .grid-two-column {
    grid-template-columns: 1fr;
  }
  .blogspage_section1 .container {
    width: 90%;
    height: auto;
    padding: 2rem !important;
    margin: 6rem auto;
  }
  .blogs_heading {
    width: 100%;
  }
  .blogspage_right1 {
    order: 1;
  }
  .blogspage_right1 .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }
  .blogspage_image1 {
    width: 100%;
    height: 100%;
    margin-top: 7rem;
    position: relative;
  }
  .blogspage_image1 img {
    width: 100%;
    height: 100%;
  }

  .grid {
    display: grid;
  }
}

/* 576px */

@media screen and (max-width: 576px) {
  .blogspage_image h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2.9rem;
  }

  .blogspage_image1 h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2.9rem !important;
  }
  .blogspage_image2 h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2.9rem;
  }

  .blogspage_image1 small {
    font-size: 1rem;
  }
  .blogspage_image2 small {
    font-size: 1rem;
  }
}
/* 576px */

@media screen and (max-width: 412px) {
}

@media screen and (max-width: 320px) {
}
