html {
  font-size: 62.5%;
}

.productpage_hero_section {
  width: 100%;
  height: 85vh;
  background: linear-gradient(
      to right,
      rgba(84, 98, 186, 0.8),
      rgba(84, 98, 186, 0.7)
    ),
    url("../Images/product_hero_img.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.productpage_hero_section .container {
  max-width: 90% !important;
  display: flex;
  justify-content: start;
  padding:  0 !important;
}

.productpage_hero_left {
  width: 40% !important;
  margin-top: 14rem;
}

.productpage_hero_left h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.5rem;
  z-index: 99;
  opacity: 0.9;
  color: #ffffff;
}

.productpage_hero_left h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.8rem;
  line-height: 6.5rem;
  opacity: 0.98;
  z-index: 999;

  color: #ffffff;
}

.productpage_hero_left p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  letter-spacing: 0.5px;
  /* or 150% */
  opacity: 0.98;
  z-index: 999;
  text-align: justify;
  color: #ededed;
}

.products {
  color: #5462ba;
}

/* media queries */

@media screen and (max-width: 1024px) {
  .productpage_hero_section {
    width: 100%;
    height: 55rem !important;
    background: linear-gradient(
        to right,
        rgba(84, 98, 186, 0.8),
        rgba(84, 98, 186, 0.7)
      ),
      url("../Images/product_hero_img.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .productpage_hero_section .container {
    display: flex;
    justify-content: start;
  }

  .productpage_hero_left {
    width: 75% !important;
    margin-top: 10rem;
  }

  .productpage_hero_left h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 6.5rem;
    opacity: 0.98;
    z-index: 999;

    color: #ffffff;
  }

  .products {
    color: #5462ba;
  }
}

@media screen and (max-width: 768px) {
  .productpage_hero_section {
    width: 100%;
    height: 55rem !important;
  }
  .productpage_hero_section::before {
    width: 100%;
    height: inherit;
    background: linear-gradient(
      to right,
      rgba(84, 98, 186, 0.8),
      rgba(84, 98, 186, 0.7)
    );
  }

  .productpage_hero_section .container {
    /* max-width: 768px; */
    display: flex;
    justify-content: start;
  }
}
/* media queries */

/* ***********************canadian section ********************/

.productpage_canadian_section {
  width: 100%;
  height: auto;
  /* background: red; */
}

.productpage_canadian_section .container {
  width: 74%;
  /* height: 60vh; */
  margin: 8rem auto;
}
.productpage_canadian_section .grid {
  display: grid;
  gap: 8rem !important;
  margin-top: 12rem !important;
}

.productpage_canadian_section .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}
.productpage_canadian_image {
  width: 100%;
  position: relative;
}
.productpage_canadian_image img {
  width: 100%;
}
.productpage_canadian_image .one_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 13rem;
}

.productpage_canadian_right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.productpage_canadian_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 4rem;
  word-spacing: 1.6px;
  /* or 115% */

  letter-spacing: 0.04em;

  color: #070a1d;
}

.candian_micro_crack {
  display: flex;
  margin: 6px 0px;
}
.candian_micro_crack h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.5rem;
  text-transform: capitalize;
  color: #070a1d;
}
.candian_micro_crack p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-transform: capitalize;
  color: #070a1d;
}

.canadian_btn button {
  width: 26rem;
  height: 5rem;
  margin-top: 2rem;
  background: #202e86;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 3rem;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.canadian_btn .fa-plus-square,
.fa-minus-square {
  width: 2rem !important;
  color: #202e86 !important;
  /* box-shadow: 0px 0px 0px  rgba(0, 0, 0, 0.4); */
  border-radius: 10px;
}
.productpage_canadian_right .sketch_design {
  font-weight: 600;
  color: #5462ba;
}
.products .rows {
  gap: 5rem !important;
}

/* ***********************canadian section ********************/

.hybrid_solar_panels {
  width: 100%;
  height: auto;
}
.hybrid_solar_panels .container:first-child {
  width: 70vw;
}
.hybrid_solar_panels h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 4rem;
  word-spacing: 1.6px;
  /* or 115% */

  letter-spacing: 0.04em;

  color: #070a1d;
}

.hybrid_solar_panels h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.9rem;
  line-height: 2.5rem;
  text-transform: capitalize;
  color: #070a1d;
}

.hybrid_solar_panels p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  word-spacing: 1px;
  text-align: center;
  color: #707070;
}

.hybrid_ongrid_section h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 4rem;
  word-spacing: 1.6px;
  letter-spacing: 0.04em;
  color: #070a1d;
}

.productpage_longi_section li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  word-spacing: 1px;
  text-align: justify;
  color: #707070;
  list-style-type: disc;
  margin: 0.5rem 0;
}

.productpage_canadian_right li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  word-spacing: 1px;
  text-align: justify;
  color: #707070;
  list-style-type: disc;
  margin: 0.5rem 0;
}
@media screen and (max-width: 1024px) {
  .productpage_canadian_section {
    width: 100%;
    height: auto;
    /* background: red; */
  }

  .hybrid_solar_panels .container:first-child {
    width: 85vw;
  }

  .products .rows {
    width: 100%;
    height: auto;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    gap: 6rem !important;
  }

  .products .rows::-webkit-scrollbar {
    overflow: hidden;
  }
  .productpage_canadian_section .container {
    width: 90% !important;
    /* height: 60vh; */
    margin: 0px auto;
  }
  .grid {
    display: grid;
    gap: 8rem !important;
  }

  .productpage_canadian_section .grid-two-column {
    grid-template-columns: 1fr;
  }
  .productpage_canadian_image {
    width: 100%;
    position: relative;
  }
  .productpage_canadian_image img {
    width: 100%;
  }
  .productpage_canadian_image .one_img {
    position: absolute;
    top: 0;
    right: 0;
    /* width: 13rem; */
  }

  .productpage_canadian_right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .candian_micro_crack {
    display: flex;
    margin: 0.6rem 0px;
  }

  .canadian_btn button {
    width: 25rem;
    height: 5rem;
    margin-top: 20px;
    background: #202e86;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 3rem;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
  }
  .productpage_canadian_right .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }

  .productpage_longi_section {
    width: 100%;
    height: auto;
    /* background: red; */
  }

  .productpage_longi_section .container {
    width: 90% !important;
    /* height: 60vh; */
    margin: 3rem auto;
    padding: 2rem;
  }

  .productpage_longi_right .sketch_design {
    font-weight: 600;
    color: #5462ba;
  }

  /* media query for longi section */
  .productpage_longi_section {
    width: 100%;
    height: auto;
    /* background: red; */
  }
  .productpage_longi_section .container {
    width: 100%;
    margin: 6rem auto;
  }

  .productpage_longi_section .grid-two-column {
    grid-template-columns: 1fr !important;
    gap: 4rem !important;
  }

  .productpage_longi_image {
    width: 100%;
    position: relative;
  }
  .productpage_longi_image img {
    width: 100%;
  }
  .productpage_longi_image .one_img {
    position: absolute;
    top: 0;
    right: 0;
    width: 25%;
  }

  .productpage_longi_right {
    width: 100%;
    height: 100%;
    order: 2 !important;
  }

  .productpage_longi_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .longi_micro_crack {
    display: flex;
    margin: 0.6rem 0rem;
    margin-left: 6%;
  }

  .longi_btn button {
    width: 22rem;
    margin-top: 20px;
    background: #202e86;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    border-radius: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 3rem;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    /* margin-left: 9rem; */
  }
}

/* ***********************media queries canadian section ********************/
@media screen and (max-width: 768px) {
  .productpage_hero_section .container {
    /* width: 90vw !important; */
    margin: 0;
    display: flex;
    justify-content: start;
  }

  .productpage_hero_left {
    width: 80% !important;
    margin-top: 10rem;
    margin-left: 5%;
  }
  .productpage_canadian_section .container {
    width: 100%;
    margin: 0px auto;
    padding: 2rem;
  }
  .productpage_canadian_section .grid {
    display: grid;
    gap: 8rem !important;
  }

  .productpage_canadian_section .grid-two-column {
    grid-template-columns: 1fr;
    gap: 4rem !important;
  }
  .productpage_canadian_image {
    width: 100%;
    position: relative;
  }
  .productpage_canadian_image img {
    width: 100%;
  }
  .productpage_canadian_image .one_img {
    position: absolute;
    top: 0;
    right: 0;
    width: 25%;
  }

  .productpage_canadian_right {
    width: 100%;
    height: 100%;
  }
  .candian_micro_crack {
    display: flex;
    margin: 0.6rem 0rem;
    margin-left: 3rem;
  }
  .productpage_canadian_right h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3.5rem;
    line-height: 4rem;
    word-spacing: 1.6px;
    /* or 115% */

    letter-spacing: 0.04em;

    color: #070a1d;
  }
  .candian_micro_crack h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 2rem;
    text-transform: capitalize;
    color: #070a1d;
  }
  .candian_micro_crack p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.5rem;
    text-transform: capitalize;
    color: #070a1d;
  }

  .canadian_btn button {
    width: 22rem !important;
    height: 50px;
    margin-top: 20px;
    background: #202e86;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    border-radius: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 3rem;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    /* margin-left: 8rem; */
  }
}

/* 449px */
@media screen and (max-width: 576px) {
  .productpage_hero_section .container {
    display: flex;
    justify-content: start;
  }

  .productpage_hero_left {
    width: 75% !important;
    margin-top: 10rem;
    margin-left: 5%;
  }

  .productpage_canadian_section {
    width: 100%;
    height: 100%;
  }
  .productpage_canadian_section .container {
    width: 90%;
    margin: 0px auto;
    padding: 2rem;
  }
  .grid {
    display: grid;
    gap: 8rem !important;
  }

  .productpage_canadian_image {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .productpage_canadian_image img {
    width: 100%;
  }
  .productpage_canadian_image .one_img img {
    position: absolute;
    top: 0;
    right: 0;
    width: 25%;
  }

  .productpage_canadian_right {
    width: 100%;
    height: 100%;
  }
  .candian_micro_crack {
    display: flex;
    margin: 6px 0px;
    margin-left: 6%;
  }

  .canadian_btn button {
    /* width: 16rem; */
    height: 5rem;
    margin-top: 20px;
    background: #202e86;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    border-radius: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 3rem;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    margin-left: 0rem;
  }
}

/* ***********************media querie canadian section ********************/

/* ***********************longi section ********************/

.productpage_longi_section {
  width: 100%;
  height: auto;
  /* background: red; */
}

.productpage_longi_section .container {
  width: 74%;
  /* height: 60vh; */
  margin: 6rem auto;
}
.productpage_longi_section .grid {
  display: grid;
  gap: 8rem !important;
}

.productpage_longi_section .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}
.productpage_longi_image {
  width: 100%;
  position: relative;
}
.productpage_longi_image img {
  width: 100%;
}
.productpage_longi_image .one_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 13rem;
}

.productpage_longi_right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.productpage_longi_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 4rem;
  word-spacing: 1px;
  /* or 115% */

  letter-spacing: 0.04em;

  color: #070a1d;
}

.longi_micro_crack {
  margin: 6px 0px;
  display: flex;
}
.longi_micro_crack h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2rem;
  text-transform: capitalize;
  color: #070a1d;
}
.longi_micro_crack p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-transform: capitalize;
  color: #070a1d;
}

.longi_btn button {
  width: 26rem;
  height: 5rem;
  background: #202e86;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 3rem;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}
.productpage_longi_right .sketch_design {
  font-weight: 600;
  color: #5462ba;
}

/* media query for longi section */

@media screen and (max-width: 768px) {
  .productpage_longi_section {
    width: 100%;
    height: auto;
    /* background: red; */
  }
  .productpage_longi_section .container {
    width: 100%;
    margin: 6rem auto;
  }

  .productpage_longi_section .grid-two-column {
    grid-template-columns: 1fr;
    gap: 4rem !important;
  }

  .grid {
    gap: 5rem !important;
  }
  .productpage_longi_image {
    width: 100%;
    position: relative;
  }
  .productpage_longi_image img {
    width: 100%;
  }
  .productpage_longi_image .one_img {
    position: absolute;
    top: 0;
    right: 0;
    width: 25%;
  }

  .productpage_longi_right {
    width: 100%;
    height: 100%;
    order: 2 !important;
  }

  .productpage_longi_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .longi_micro_crack {
    display: flex;
    margin: 0.6rem 0rem;
    margin-left: 6%;
  }

  .longi_btn button {
    width: 22rem;
    margin-top: 2rem;
    background: #202e86;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    border-radius: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 3rem;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    /* margin-left: 10rem; */
  }
}

/* ***********************canadian section ********************/
@media screen and (max-width: px) {
  .products .rows {
    gap: 5rem !important;
  }

  .productpage_canadian_section .container {
    width: 80vw !important;
    margin: 8rem auto;
  }
  .productpage_longi_section .container {
    width: 80vw !important;
    /* height: 60vh; */
    margin: 6rem auto;
  }
}

@media screen and (max-width: px) {
  .products .rows {
    gap: 2rem !important;
  }

  .productpage_canadian_section .container {
    width: 90vw !important;
    margin: 8rem auto;
  }
  .productpage_longi_section .container {
    width: 90vw !important;
    /* height: 60vh; */
    margin: 6rem auto;
  }
}
