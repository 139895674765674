html{
  font-size: 62.5%;
}

.cards {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 3.9rem;
  margin-bottom: 10%;
  /* background-color: red; */
}

.main_service_section{
  width: 100%;
  height: auto;
  /* background-color: rebeccapurple; */
}

.main_service_section .container-fluid{
  width: 80vw;
  /* background-color: rebeccapurple; */
  margin: 1rem auto !important;
}
.main_service_section .grid{
  display: grid;
  gap: 10rem !important;
}

.main_service_section .grid-three-column{
  grid-template-columns: 1fr 1fr 1fr;
}
.cards__image {
  margin-top: 1rem !important;
  width: 9rem;
  /* height: 9rem; */
  /* background-color: rebeccapurple; */
}

.cards__title {
  margin-top: 0.9rem;
  justify-content: center;
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.3rem;
  line-height: 3rem;
  text-align: center;
  color: #070a1d;
}

.cards__desc {
  width: 28rem;
  height: 9rem;
  margin-top: 1.6rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  word-spacing: 1px;
  line-height: 2rem;
  text-align: center;
  color: #707070;
  /* background-color: royalblue; */
}

.light_img{
  width: 8rem;
}

.cards__learn {
  height: 10px;
  margin-top: 2rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: #5ea410;
}

.cards__learn:hover{
  color: #5462ba;
}

.compress {
  line-height: 1.7rem !important; 
}

/* media quries */


@media only screen and (max-width: px) {
.main_service_section .container {
    width: 90vw;
    margin-top: 5rem;
}
.main_service_section .grid{
  gap: 7rem !important;
}

.main_service_section .container-fluid{
  width: 89vw;
  /* background-color: rebeccapurple; */
  margin: 1rem auto !important;
}

.cards__desc {
  /* width: 26rem; */
}
}

@media only screen and (max-width: px) {
.main_service_section .container {
    width: 90vw;
    margin-top: 3rem;
}
.main_service_section .grid{
  gap: 4rem !important;
}

.main_service_section .container-fluid{
  width: 90vw;
  margin: 1rem auto !important;
}

}
@media only screen and (max-width: 1024px) {
.main_service_section .grid{
  display: grid;
  gap: 5rem !important;
}

.mobile__head {
    width: 90% !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 4.5rem;
    margin-top: 3rem;
    color: #070a1d;
    margin-left: 2rem !important;
}

.main_service_section .grid-three-column{
  grid-template-columns: 1fr;
}
 .serviced__para {
    /* width: 80rem; */
    height: auto;
}
 
  .main_service_card {
    gap: 0 !important;
  }
  .cards {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 0 5rem;
  margin-top: 0 !important; 
  margin-bottom: 0% !important;
  }
  .cards__desc {
    /* width: 35rem; */
    height: auto !important;
    margin-top: 1.5rem;
    text-align: center;
    color: #707070;
  }
 
  .cards__learn {
    height: 5rem;
    margin-top: 1.4rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: #5ea410;
  }
}

@media screen and (max-width: 768px) {
  
  .main_service_section {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem !important;
    gap: 0px !important;
    padding: 3rem;
  }
  .main_service_card {
    gap: 0 !important;
  }

 .serviced__para {
    width: 80rem;
    height: auto;
}
 
  .main_service_card {
    gap: 0 !important;
  }
  .cards {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 0 5rem;
  }

  .cards__desc {
    height: auto !important;
    margin-top: 1.5rem;
    text-align: center;
    color: #707070;
  }


}

@media only screen and (max-width: 576px) {
  /* service section */
 .serviced__para {
    width: 80rem;
    height: auto;
}
 
  .main_service_card {
    gap: 0 !important;
  }
  .cards {
    /* width: 70rem !important; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 0 5rem;
  }

  .cards__desc {
    /* width: 25rem; */
    height: auto !important;
    margin-top: 1.5rem;
    text-align: center;
    color: #707070;
  }
}

@media only screen and (max-width: 490px) { 
  .cards {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 0 5rem;
  }

  .cards__desc {
    /* width: 25rem; */
    height: auto !important;
    margin-top: 1.5rem;
    text-align: center;
    color: #707070;
  }
}
