html {
  font-size: 62.5%;
}

.about_main_div {
  width: 100%;
  height: auto;
}
.about__head {
  height: 5.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 4rem;
  color: #5462ba;
  /* background: brown; */
}

.about__title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 3.8rem;
  line-height: 6rem;
  letter-spacing: 0.04em;
  color: #000000;
}

.about__title .wend {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.8rem;
  line-height: 4rem !important;
  letter-spacing: 0.04em;
}
.about__desc {
  height: auto;
  margin-top: 3%;
}

.about__desc p {
  font-family: "Poppins";
  font-style: normal;
  font-size: 1.8rem;
  line-height: 2.7rem;
  text-align: justify;
  color: #707070;
  word-spacing: 0.2rem;
  letter-spacing: 0.3px;
}

.about_main_div .container {
  width: 80vw;
  padding: 2rem;
  margin-top: 10rem;
}
.about_main_div .grid {
  display: grid;
  justify-items: flex-end;
  gap: 10rem !important;
  justify-content: center;
  align-items: center;
}
.about_main_div .grid-two-column {
  grid-template-columns: 1fr 1fr;
}

.image__div {
  /* height: 40rem; */
  width: 100%;
}
.image__div img {
  width: 90%;
  height: auto;
}

/* CEO MESSAGE */
.ceo_mision_section {
  width: 100%;
  margin-top: 2rem;
  /* background: rebeccapurple; */
}

.ceo_mision_section .container {
  width: 80vw;
  padding: 2rem;
}
.ceo_mision_section .grid {
  display: grid;
  gap: 14rem !important;
}
.ceo_mision_section .grid-two-column {
  grid-template-columns: 1fr 1fr;
}
.ceo_down_section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12rem;
}
.ceo__mission {
  /* width: 17.9rem; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 4.5rem;
  color: #5462ba;
}

.ceo__leading {
  /* width: 43.9rem; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 6rem;
  letter-spacing: 0.04em;
  color: #070a1d;
}

.ceo__message {
  margin-top: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-size: 1.8rem;
  line-height: 2.7rem;
  text-align: justify;
  color: #707070;
  word-spacing: 1px;
  /* letter-spacing: 0.6px; */
}

.ceo__mainImage {
  height: 100%;
  width: 100%;
  position: relative;
}

.ceo__mainImage img {
  height: 100%;
  width: 100%;
}

.ceo__name {
  position: absolute;
  width: 20rem;
  height: 7rem;
  bottom: 7%;
  left: -20%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  padding: 0.6rem;
}

.ceo__mr {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.8rem;
  text-align: justify;
  color: #000000;
}

.ceo__company {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.5rem;
  text-align: justify;

  color: #5462ba;
}

/* ceo vision */
.ceo_vision_section {
  width: 100%;
  margin-top: 5rem;
  height: auto;
}
.ceo_vision_section .container {
  width: 78vw;
  padding: 0rem !important;
}
.ceo_vision_section .grid {
  display: grid;
  gap: 14rem !important;
}
.ceo_vision_section .grid-two-column {
  grid-template-columns: 1fr 1fr;
}
.ceo__visionImage {
  height: 100%;
  /* width: 37rem; */
}

.ceo__visionImage img {
  width: 100%;
  height: 100%;
}

.ceo__visionMessage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.7rem;
  color: #5462ba;
}

.ceo__wants {
  /* width: 540px; */
  /* height: 130px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 4rem;
  line-height: 6.3rem;
  letter-spacing: 0.04em;

  color: #070a1d;
}

.achive {
  font-weight: 600;
}

.ceo__visionPara {
  /* width: 38rem; */
  height: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  text-align: justify;
  color: #707070;
  word-spacing: 1px;
}

/* media queries end of hero section */
.enjoy_section {
  width: 100%;
  height: auto;
}
.enjoy_section .container {
  width: 80vw !important;
  height: auto;
}
.enjoy__image {
  /* width: 12rem !important; */
  height: 100%;
  /* background: #070a1d; */
}

.enjoy__image img {
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* about cards */
.about_cards {
  width: 80vw;
  /* height: 100%; */
  margin: 0 auto;
  padding: 1rem;
}

.about__maindiv {
  margin-top: 3rem;
  height: 25rem;
  /* width: 26rem; */
  background: #ffffff;
  border: 1px solid #dcdcdc;
  padding: 1.9rem;
}

.about_cards .grid {
  display: grid;
  gap: 2rem !important;
}

.about_cards .grid-four-column {
  grid-template-columns: repeat(4, 1fr);
}

.about__details {
  /* width: 40px; */
  /* height: 60px; */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.4rem;
  line-height: 6rem;
  letter-spacing: 0.04em;
  color: #000000;
}

.about__accuracy {
  /* width: 270px; */
  /* height: 73px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.1rem;
  line-height: 4rem;
  color: #5462ba;
}

.about__team {
  margin-top: 1.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: #565656;
  word-spacing: 2px;
}

/* another section */

/* value section */

.value {
  margin-bottom: 3rem;
  height: 24rem;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      to left,
      rgba(84, 98, 186, 0.8),
      rgba(82, 78, 183, 0.8)
    ),
    url("../Images/value.png");
}

.value__head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 2.5rem;
  padding-top: 2%;
  margin-left: 5%;
  text-transform: capitalize;
  color: #ffffff;
}

.value__range {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.5rem !important;
  letter-spacing: 0.04em;
  padding-top: 2rem;
  text-align: center;
  color: #ffffff;
}

.value__contain {
  width: 50%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 2.8rem;
  text-align: center;
  margin: 0px auto;
  word-spacing: 1px;
  letter-spacing: 0.2px;
  padding-top: 4rem;
  color: #ffffff;
}

/* team section */
.team {
  height: auto;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      to left,
      rgba(84, 98, 186, 0.8),
      rgba(82, 78, 183, 0.8)
    ),
    url("../Images/team.png");
  padding-bottom: 20rem;
}
.team_down_section .container {
  width: 62vw !important;
}
.team__para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.3rem;
  line-height: 3.5rem;
  padding-top: 2.5rem;
  text-align: center;
  color: #ffffff;
}

.team__amazing {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 6rem;
  letter-spacing: 0.04em;
  padding-top: 2rem;
  text-align: center;
  color: #ffffff;
}

.team .flex {
  width: 75%;
  align-items: center;
  gap: 10rem;
}

.team_down_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* visit section */

.visit_section {
  width: 100%;
  /* background: red; */
}

.board_bazar_section .container {
  width: 71vw !important;
  padding: 0 !important;
}

.visit_left_office {
  width: 100% !important;
}

.visit_left_office .visit_office {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.7rem;
  color: #5462ba;
}
.visit_left_office .visit_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 4.5rem;
  letter-spacing: 0.04em;
  color: #000000;
}
.visit_left_office .visit_us {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.5rem;
  letter-spacing: 0.04em;
  color: #5462ba;
}

.visit_left_office .visit_para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  /* line-height: 2.8rem; */
  word-spacing: 1px;
  text-align: justify;
  color: #707070;
}

/* board bazar section */

.board_bazar_section {
  width: 100%;
  height: auto;
}
.board_bazar_section .container {
  width: 75vw !important;
}

.board_bazar_section .grid {
  display: grid;
  gap: 0rem 4rem !important;
}

.board_bazar_section .grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}

.right_board .board_image {
  width: 100%;
}
.right_board .board_image .map {
  width: 100%;
  height: 35rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.map_hieght_width {
  width: 100%;
  height: 100%;
}

.left_board {
  width: 100%;
  height: 100%;
  padding: 2.3rem 3.5rem;
  background: #ffffff;
  border: 1px solid #dcdcdc;
}

.board_bazar_section {
  width: 100%;
  height: auto;
}

.board_bazar_section .container {
  width: 71vw;
  margin: 0rem auto !important;
}

.board_left_down {
  background: #ffffff;
  border: 1px solid #dcdcdc;
}

.left_side .board_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.7rem;
  word-spacing: 2px;
  letter-spacing: 0.04em;
  color: #000000;
}

.left_side .board_para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  /* line-height: 3rem; */
  text-align: justify;
  color: #707070;
  letter-spacing: 0.5px;
  word-spacing: 1px;
}

.left_side .btn {
  margin: 1.6rem 0px;
  width: 15rem;
  height: 4rem;
  background: #202e86;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
  color: white;
  font-size: 1.6rem;
}

.blue_area {
  margin-top: 2rem;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  padding: 2.3rem 3.5rem;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.toggle_btns {
  /* padding: 2.9rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile_blue_area_div {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mobile_blue_area_flex {
  width: 100% !important;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.left_side .blue_area h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.9rem;
  line-height: 1.8rem;
  letter-spacing: 0.04em;
  word-spacing: 1px;
  color: #000000;
}
.board_bazar_section .fa-square-minus,
.fa-square-plus {
  width: 2rem !important;
  color: #202e86;
  /* box-shadow: 0px 0px 0px  rgba(0, 0, 0, 0.4); */
  border-radius: 10px;
}

/* board bazar section */

.board_bazar_section_mobile_view {
  display: none;
}

.right_board_mobile_view .board_image .map {
  width: 100%;
  height: 40rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-left: 0% !important;
}

.board_bazar_section_mobile_view .grid {
  gap: 0rem !important;
  margin: 0rem auto !important;
}
.board_bazar_section_mobile_view .grid-one-column {
  grid-template-columns: 1fr !important;
  grid-row-start: auto;
}

.visit_left_office {
  width: 74vw !important;
  height: auto;
  margin: 1rem auto;
}

.another_div_blue_area {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about_cards_another {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}
/* section end */
/* media queries  of hero section*/

@media only screen and (max-width: 1350px) {
}

@media only screen and (max-width: 1250px) {
  .about_main_div .container {
    width: 85vw;
  }

  .about_cards {
    width: 85vw;
    /* height: 100%; */
    margin: 0 auto;
    padding: 1rem;
  }

  .board_bazar_section .container {
    width: 85vw !important;
    margin: 0rem auto !important;
  }
  .about_main_div .grid {
    gap: 8rem !important;
  }
  .enjoy_section .container {
    width: 85vw !important;
    padding: 0;
  }
  .logos {
    width: 100%;
    /* margin-top: 8%; */
    /* padding-bottom: 5% !important; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    opacity: 0.9;
    gap: 4rem !important;
  }
  .about__maindiv .container {
    width: 85vw !important;
    padding: 0 !important;
  }
  .logos img {
    width: 8% !important;
  }
  .ceo_vision_section .container {
    width: 85vw;
    padding: 0rem !important;
  }
  .ceo_mision_section .container {
    width: 85vw;
    padding: 0rem !important;
  }
  .ceo_vision_section .grid {
    display: grid;
    gap: 10rem !important;
  }
  .ceo_mision_section .grid {
    display: grid;
    gap: 10rem !important;
  }
  .about_cards .container {
    width: 85vw !important;
    margin: 0 auto;
    padding: 0 !important;
  }
}

@media only screen and (max-width: 1150px) {
  .about_main_div .container {
    width: 85vw;
    padding: 0;
  }
  .about_main_div .grid {
    gap: 3rem !important;
  }
  .logos {
    width: 100%;
    margin-top: 8%;
    padding-bottom: 5% !important;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    opacity: 0.9;
    gap: 2rem !important;
  }
  .logos img {
    width: 7% !important;
  }
  .ceo_vision_section .grid {
    display: grid;
    gap: 10rem !important;
  }
  .ceo_mision_section .grid {
    display: grid;
    gap: 10rem !important;
  }
    .about_cards .container {
    width: 85vw !important;
    margin: 0 auto;
    padding: 0 !important;
  }
}
@media only screen and (max-width: 1024px) {
  .about_main_div {
    width: 100%;
    height: 100%;
  }

  .about_main_div .container {
    width: 90vw !important;
    padding: 2rem !important;
    margin-top: 10rem;
  }
  .about_main_div .grid {
    display: grid;
    justify-items: flex-end;
    gap: 2rem !important;
    justify-content: center;
    align-items: center;
  }
  .about_main_div .grid-two-column {
    grid-template-columns: 1fr;
  }

  .about_left {
    padding-top: 1px !important;
    width: 100% !important;
    height: auto;
  }
  .about__head {
    width: 100% !important;
    height: auto;
  }

  .image__div {
    height: 100%;
    width: 100%;
  }
  .image__div img {
    width: 100%;
    height: 100%;
  }

  .about__desc {
    width: 100%;
    height: auto;
  }

  .about__desc p {
    height: auto;
    font-family: "Poppins";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 1.7rem;
    line-height: 2.5rem;
    text-align: justify;
    color: #707070;
    word-spacing: 0 !important;
    letter-spacing: 0.3px;
  }

  /* enjoy image */
  .enjoy_section {
    width: 100%;
    height: 100%;
  }
  .enjoy_section .container {
    width: 90vw !important;
    height: auto;
    padding: 2 !important;
  }
  .enjoy__image {
    height: 100%;
  }

  .enjoy__image img {
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  /* cards css */

  .about_cards {
    padding: 10px;
    width: 100%;
    height: auto;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .about_cards::-webkit-scrollbar {
    overflow: hidden;
  }

  .about_cards_another {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    margin: 0 auto !important;
  }

  .about_cards .container {
    width: 87vw !important;
    margin: 0 auto;
    /* padding: 0 2rem; */
  }
  .about__maindiv {
    margin-top: 30px;
    height: 18rem;
    width: 18rem;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    padding: 19px;
  }

  .about__details {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 50px;
    letter-spacing: 0.04em;
    color: #000000;
  }

  .about__accuracy {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1px;
    color: #5462ba;
  }

  .accuracy_cards {
    /* margin-left: 10rem; */
  }

  .about__team {
    width: 100%;
    margin-top: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 2rem;
    color: #565656;
    word-spacing: 2px;
  }

  .hand__image {
    width: 100% !important;
    height: 100%;
  }
  .hand__image .container {
    width: 100% !important;
    height: 100%;
  }
  .hand__title {
    position: absolute;
    left: 1%;
    top: 3%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 7.5rem;
    text-align: center;
    padding: 20px;
    text-transform: capitalize;
    color: #ffffff;
    opacity: 0.9;
  }

  .logos {
    width: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    gap: 2rem !important;
    padding: 4rem 0rem;
  }
  .logos::-webkit-scrollbar {
    overflow: hidden;
  }

  /* ceo mission */

  .ceo_mision_section {
    width: 100%;
    height: 100%;
  }

  .ceo_mision_section .container {
    width: 90vw !important;
    padding: 2rem !important;
  }
  .ceo_mision_section .grid {
    display: grid;
    gap: 2rem !important;
  }
  .ceo_mision_section .grid-two-column {
    grid-template-columns: 1fr !important;
  }

  .ceo__message {
    margin-top: 1rem;
    width: 100% !important;
    /* height: 290px; */
    background: white;
    text-align: justify;
    color: #707070;
    word-spacing: 1px;
    /* letter-spacing: 0.6px; */
  }
  .ceo__mainImage {
    margin-top: 2rem;
    height: 100%;
    width: 100% !important;
    position: relative;
    padding: 0 2rem;
  }

  .ceo__mainImage img {
    height: 100%;
    width: 100%;
  }

  .ceo__leading {
    width: 100%;
    /* height: 140px; */
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 6rem;
    letter-spacing: 0.04em;
    color: #070a1d;
  }
  .ceo__name {
    position: absolute;
    width: 20rem;
    height: 7rem;
    bottom: 1% !important;
    left: 0% !important;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    padding-left: 1rem;
    /* justify-content: center; */
  }

  /* ceo vission */

  .ceo_vision_section {
    margin-top: 5rem;
    width: 100% !important;
    height: 100%;
  }

  .ceo_vision_section .container {
    width: 90% !important;
    /* padding: 2rem 0 !important; */
    height: auto;
    display: flex;
    gap: 1rem !important;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 2rem !important;
  }
  .ceo_vision_section .ceo_down_section {
    width: 100%;
    height: 100%;
  }
  .ceo_vision_section .grid {
    display: grid;
  }

  .ceo__visionImage {
    order: 2 !important;
  }
  .ceo_vision_section .grid-two-column {
    grid-template-columns: 1fr !important;
  }
  .ceo__visionImage {
    height: 100% !important;
    width: 100% !important;
    padding: 0 2rem;
  }

  .ceo__vision {
    width: 100% !important;
    height: 100%;
  }

  .ceo__visionPara {
    width: 100% !important;
    height: auto;
    font-family: "Poppins";
    font-style: normal;
    text-align: justify;
    color: #707070;
    word-spacing: 1px;
    grid-row-start: 2 !important;
  }

  /* value section */

  .value {
    margin-bottom: 3rem;
    height: 35rem !important;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100% !important;
    background-image: linear-gradient(
        to left,
        rgba(84, 98, 186, 0.8),
        rgba(82, 78, 183, 0.8)
      ),
      url("../Images/value_mobile.png") !important;
  }

  .value__head {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 2.5rem;
    padding-top: 7% !important;
    padding-left: 2%;
    /* text-align: center; */
    text-transform: capitalize;
    color: #ffffff;
  }

  .value__range {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 3rem !important;
    line-height: 1.5rem;
    letter-spacing: 0.04em;
    padding-top: 9% !important;
    text-align: center;
    color: #ffffff;
  }

  .value__contain {
    width: 60% !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 2.8rem;
    text-align: center;
    margin: 0px auto;
    word-spacing: 1px;
    letter-spacing: 0.2px;
    padding-top: 4rem;
    color: #ffffff;
  }
  .board_bazar_section {
    display: none;
  }

  .board_bazar_section_mobile_view {
    display: block !important;
  }
  .blue_area {
    width: 100% !important;
  }
  .right_board_mobile_view .container {
    width: 90vw !important;
  }
  .visit_left_office {
    width: 100% !important;
    height: auto;
    margin: 1rem 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .about_main_div {
    width: 100%;
    height: 100%;
  }
  .about_main_div .container {
    width: 90vw !important;
    padding: 2rem !important;
    margin-top: 10rem;
  }
  .about_left {
    padding-top: 1px !important;
    width: 100% !important;
    height: auto;
  }
  .about__head {
    width: 100% !important;
    height: auto;
  }

  .image__div {
    padding-top: 1rem !important;
    height: 100%;
    width: 100%;
  }
  .image__div img {
    width: 100%;
    height: 100%;
  }

  .about__desc {
    width: 100%;
    height: auto;
  }

  .about__desc p {
    height: auto;
    font-family: "Poppins";
    font-style: normal;
    /* font-weight: 500; */
    /* font-size: 1.8rem; */
    /* line-height: 2.5rem; */
    text-align: justify;
    color: #707070;
    word-spacing: 0 !important;
    letter-spacing: 0.3px;
  }
}

/* media queries  of hero section*/
@media only screen and (max-width: 450px) {
  .about_main_div {
    width: 100%;
    height: 100%;
  }
  .about_main_div .container {
    width: 90vw !important;
    padding: 2rem !important;
    margin-top: 10rem;
  }
  .about_left {
    padding-top: 1px !important;
    width: 100% !important;
    height: auto;
  }
  .about__head {
    width: 100% !important;
    height: auto;
  }

  .about__title {
    margin-top: 1rem;
    width: 100%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 2.7rem;
    line-height: 4rem;
    letter-spacing: 0.04em;
    color: #000000;
  }
  .image__div {
    padding-top: 10px !important;
    height: 100%;
    width: 100%;
  }
  .image__div img {
    width: 100%;
    height: 100%;
  }

  .about__desc {
    width: 100%;
    height: auto;
  }
  .candaian_img {
    margin-left: 30rem !important;
  }
}

@media screen and (max-width: 768px) {
  .enjoy_section {
    width: 100%;
    height: 100%;
  }
  .enjoy_section .container {
    width: 90% !important;
    height: 100%;
    padding: 2rem !important;
    padding: 0.6rem;
  }
  .enjoy__image {
    height: 100%;
  }

  .enjoy__image img {
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

/* end of enjoy section media queires */

/* media query for about section */

@media screen and (max-width: 768px) {
  .about_cards {
    padding: 1rem;
    width: 100%;
    height: auto;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .about_cards::-webkit-scrollbar {
    overflow: hidden;
  }
  .about__maindiv {
    margin-top: 3rem;
    height: 17rem;
    width: 17rem;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    padding: 1.9rem;
  }

  .about__details {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 5rem;
    letter-spacing: 0.04em;
    color: #000000;
  }

  .about__accuracy {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1px;
    color: #5462ba;
  }

  .about__team {
    width: 100%;
    margin-top: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 2rem;
    color: #565656;
    word-spacing: 2px;
  }
  .accuracy_cards {
    /* margin-left: 15rem; */
  }
}

/* end of quier for about section */

.hand__image {
  height: 36rem !important;
  width: 100%;
  margin-top: 6rem;
  background-image: url("../Images/hand.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3rem;
  position: relative;
}
.hand__image .container {
  width: 100% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hand__image::before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to left,
    rgba(84, 98, 186, 0.8),
    rgba(82, 78, 183, 0.8)
  );
}

.hand__title {
  position: absolute;
  left: 1%;
  top: 3%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3.5rem;
  line-height: 7.5rem;
  text-align: center;
  padding: 2rem;
  text-transform: capitalize;
  color: #ffffff;
  opacity: 0.9;
}

.logos {
  width: 100%;
  height: auto;
  /* margin-top: 8%; */
  /* padding-bottom: 5% !important; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  opacity: 0.9;
  gap: 8rem;
}

.logos img {
  width: 15% !important;
  /* height: 100%; */
}

/* media queries for hand images */

@media screen and (max-width: 768px) {
  .hand__image {
    width: 100% !important;
    height: 100%;
  }
  .hand__image .container {
    width: 90vw !important;
    height: auto;
    padding: 2rem;
  }
  .hand__title {
    position: absolute;
    left: 1%;
    top: 3%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 7.5rem;
    text-align: center;
    padding: 2rem;
    text-transform: capitalize;
    color: #ffffff;
    opacity: 0.9;
  }

  .logos {
    width: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    gap: 5rem;
  }
  .logos::-webkit-scrollbar {
    overflow: hidden;
  }
  .logos img {
    width: 29% !important;
    /* height: 100%; */
  }
  .candaian_img {
    width: 20% !important;
    margin-left: 40rem !important;
  }
}

/* media queries for hand images end*/

@media only screen and (max-width: 768px) {
  .ceo_mision_section {
    width: 100%;
    height: 100%;
  }
  .ceo_mision_section .container {
    width: 90vw;
    padding: 2rem;
  }
  .ceo_down_section {
    width: 100%;
    height: 100%;
    display: block;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: center;
  }

  .ceo__mainImage {
    margin-top: 2rem;
    height: 100%;
    width: 100%;
    position: relative;
  }

  .ceo__mainImage img {
    height: 100%;
    width: 100%;
  }

  .ceo__leading {
    width: 100%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 6rem;
    letter-spacing: 0.04em;
    color: #070a1d;
  }
  .ceo__name {
    position: absolute;
    width: 20rem;
    height: 7rem;
    bottom: 3%;
    left: -1%;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    padding-left: 1rem;
    /* justify-content: center; */
  }
}

/* media query for vissio section */
@media only screen and (max-width: 768px) {
  .ceo_vision_section {
    margin-top: 5rem;
    width: 100%;
    height: 100%;
  }

  .ceo_vision_section .grid-two-column {
    grid-template-columns: 1fr;
  }

  .ceo_vision_section .grid {
    gap: 2rem 0 !important;
  }
  .ceo__visionImage {
    order: 2 !important;
  }
  .ceo_vision_section .container {
    width: 90vw;
    padding: 2rem;
    height: auto;
    gap: 2rem !important;
  }
  .ceo_vision_section .ceo_down_section {
    width: 100%;
    height: 100%;
  }

  .ceo__visionImage {
    height: 100%;
    width: 100%;
  }

  .ceo__vision {
    width: 100%;
  }

  .ceo__visionPara {
    width: 100% !important;
    height: auto;
    font-family: "Poppins";
    font-style: normal;
    text-align: justify;
    color: #707070;
    word-spacing: 1px;
  }
}
@media only screen and (max-width: 768px) {
  .board_bazar_section {
    display: none;
  }

  .board_bazar_section_mobile_view {
    display: block !important;
  }
}
/* medua query for value section */

@media (max-width: 768px) {
  .value {
    margin-bottom: 3rem;
    height: 35rem;
    width: 100%;
    background-position: center;
    background-size: 100%;
    background-image: linear-gradient(
        to left,
        rgba(84, 98, 186, 0.8),
        rgba(82, 78, 183, 0.8)
      ),
      url("../Images/value_mobile.png");
  }

  .value__head {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 2.5rem;
    padding-top: 7%;
    margin-left: 3%;
    /* text-align: center; */
    text-transform: capitalize;
    color: #ffffff;
  }

  .value__range {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 1.5rem;
    letter-spacing: 0.04em;
    padding-top: 7%;
    text-align: center;
    color: #ffffff;
  }

  .value__contain {
    width: 80%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 2.8rem;
    text-align: center;
    margin: 0px auto;
    word-spacing: 1px;
    letter-spacing: 0.2px;
    padding-top: 4rem;
    color: #ffffff;
  }

  .board_bazar_section_mobile_view .container {
    width: 90vw !important;
  }
}

/* media queries */
@media screen and (max-width: 1024px) {
  .visit_left_office {
    margin: 3rem auto;
  }
  .board_bazar_section_mobile_view .container {
    width: 90vw !important;
  }
}

@media screen and (max-width: 576px) {
  .accuracy_cards {
    /* margin-left: 18 rem; */
  }
}
