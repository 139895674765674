html{
  font-size: 62.5% ;
}

.service_hero_section {
  width: 100%;
  height: 85vh;
  background: url(../Images/service_hero.png) ;
  background-size: 65rem;
  background-repeat: no-repeat;
  background-position: 85% 70%;
}
.service_hero_section::before {
  content: "";
  width: 100%;
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(84, 98, 186, 0.8),
    rgba(84, 98, 186, 0.7)
  );
}

.service_hero_section .container {
  width: 90% !important;
  display: flex;
  justify-content: start;
  padding: 0 !important ;
}

.service_hero_left {
  width: 40% !important;
  margin-top: 14rem;
}

.service_hero_left h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.5rem;
  z-index: 99;
  opacity: 0.9;
  color: #ffffff;
}

.service_hero_left h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.8rem;
  line-height: 6.5rem;
  opacity: 0.98;
  z-index: 999;

  color: #ffffff;
}

.service_hero_left p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  letter-spacing: 0.5px;
  opacity: 0.98;
  z-index: 999;
  text-align: justify;
  color: #ededed;
}

.service_hero_image {
  margin-top: 10rem;
  /* background: wheat; */
  /* width: 120%; */
}

.service_hero_image img {
  width: 120%;
  height: 100%;
  background-size: 100%;
  margin-left: -8rem;
}

/* our portfolio section */



/* end of portfolio section */

/* start services section */

.portfolio_right .offering_portfolio_p{
  font-family: 'Poppins';
font-style: normal;
font-weight: 600 !important;
font-size: 1.6rem !important;
line-height: 4.5rem !important;
/* identical to box height, or 180% */


color: #000000 !important;
}

.our_services_section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.our_services_section .container {
  width: 75%;
}

.our_services_section .servies_img1 {
  margin-left: 0 !important;
}

.our_services_section .services_right h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 4.5rem;
  color: #000000;
}

.our_services_section .services_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 2.8rem;
  line-height: 4rem;
  /* or 136% */

  text-transform: capitalize;

  color: #3f3f3f;
}

.our_services_section .services_right p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem ;
  line-height: 2.7rem;
  word-spacing: 1px;
  text-align: justify;
  color: #707070;
  margin-top: 10px;
}

.our_services_section .services_right h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
  text-transform: uppercase;
  color: #5ea410;
}


  .our_portfolio_section .portfolio_left_section_offering{
  display: flex;
}

/* Solution cards */
.solution {
  width: 100%;
  height: auto;
  margin: 8rem 0;
}

.solution_heading {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.solution_heading h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 7.5rem;
  text-align: center;
  text-transform: capitalize;

  color: #3f3f3f;
}

.solution_heading p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  text-align: center;
  color: #707070;
}

.carding {
  width: 28rem;
  height: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  border: 2px solid #5462ba;
  /* background: green ; */
  padding: 2.5rem;
  transition: all 0.5s linear;
  cursor: pointer;
}

.carding figure {
  width: 15rem;
  /* background: black; */
}

.solution .carding:hover {
  border: 2px solid #5462ba;
  transform: scale(1.03);
  cursor: pointer;
  background: #5462ba;
  color: white !important;
}

.carding:hover h2 {
  color: white;
}

.carding:hover p {
  color: white;
}

.mySwiper{
  z-index: 0 !important;
}

.carding:hover img {
  filter: brightness(0) invert(1);
  color: white;
}

.solution .carding figure {
  width: 16rem;
  /* background: rebeccapurple; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carding h2 {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 5rem;

  color: #070a1d;
}

.carding p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: #707070;
}

.carding_third figure {
  width: 23rem !important;
}

.carding_first figure {
  width: 17rem !important;
}

.carding_second img {
  filter: invert(58%) sepia(9%) saturate(3339%) hue-rotate(195deg)
    brightness(94%) contrast(93%);
  /* color: white; */
}

.solution .carding figure {
  width: 20rem;
}

.solution .rows {
  display: flex;
  gap: 5rem !important;
}


/* products cards */

.offering_mobile_slider{
  width: 28.5rem;
  height: auto;
 
}
/* media queries */

.desktop_view_offering_cards{
  display: block !important;
}

.mobile_view_offering_cards{
  display: none !important;
}


@media screen and (max-width: px) {
  .service_hero_section .container {
  width: 90% !important;
  display: flex;
  justify-content: start;
  padding-left: 0rem ;
}



.service_hero_left {
  width: 45% !important;
  margin-top: 13rem !important;
  /* background: grey; */
}

.service_hero_left h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.5rem;
  z-index: 99;
  opacity: 0.9;
  color: #ffffff;
}

.service_hero_left h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.8rem;
  line-height: 6.5rem;
  opacity: 0.98;
  z-index: 999;

  color: #ffffff;
}

.service_hero_left p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  letter-spacing: 0.5px;
  opacity: 0.98;
  z-index: 999;
  text-align: justify;
  color: #ededed;
}

}

@media screen and (max-width: px) {
  
.solution .rows {
  display: flex;
  gap: 3rem !important;
}
}


@media only screen and (max-width: 1024px) {

 
.swiper-wrapper {
  margin-left: 2% !important;
}


.portfolio_left_section_offering{
 width: 95% !important; 
}

.desktop_view_offering_cards{
  display: none !important;
}


.mobile_view_offering_cards{
  display: block !important;
  z-index: 0 !important;
  position: relative;
}

.service_hero_section {
  width: 100%;
  height: 53rem;
  background: url(../Images/offering_mobile_img.png) right;
  /* background-size: 100% 100%; */
  background-repeat: no-repeat;
}
.service_hero_section::before {
  content: "";
  width: 100%;
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(84, 98, 186, 0.8),
    rgba(84, 98, 186, 0.7)
  );
}

.service_hero_section .container {
  width: 90vw !important;
  display: flex;
  justify-content: start;
}


.solution .rows::-webkit-scrollbar {
  overflow: hidden;
}
.service_hero_left {
  width: 75% !important;
  margin-top: 10rem;
  /* background: grey; */
}

.service_hero_left h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  z-index: 99;
  opacity: 0.9;
  color: #ffffff;
}

.service_hero_left h2 {
  opacity: 0.98;
  z-index: 999;

  color: #ffffff;
}

.service_hero_left p {
  text-align: justify;
  color: #ededed;
}

.service_hero_image {
  margin-top: 10rem;
  /* background: wheat; */
  /* width: 120%; */
}


  .solution .rows {
    width: 100%;
    height: auto;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .solution .rows::-webkit-scrollbar {
    overflow: hidden;
  }



  /* our portfolio sectoin */

  .our_portfolio_section .portfolio_left_section_offering{
  display: flex;
}

.our_portfolio_section .portfolio_heading{
  display: none;
}



/* products section */

.products{
  width: 100%;
  height: auto;
}
.products .container{
  width: 100%;
  height: auto;
}



.products .rows{
  width: 100%;
  height: auto;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
}

.products .rows::-webkit-scrollbar{
  overflow: hidden;
}
}



@media only screen and (max-width: 768px) {

.swiper-wrapper {
  margin-left: 4% !important;
}

.service_hero_section {
  width: 100%;
  /* height: 55rem; */
  background: url(../Images/offering_mobile_img.png) right;
  /* background-size: 100% 100%; */
  background-repeat: no-repeat;
}
.service_hero_section::before {
  content: "";
  width: 100%;
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(84, 98, 186, 0.8),
    rgba(84, 98, 186, 0.7)
  );
}

.service_hero_section .container {
  width: 90vw !important;
  display: flex;
  justify-content: start;
}


.solution .rows::-webkit-scrollbar {
  overflow: hidden;
}
.service_hero_left {
  width: 80% !important;
  margin-top: 10rem;
  /* background: grey; */
}

.service_hero_left h1 {
  z-index: 99;
  opacity: 0.9;
  color: #ffffff;
}

.service_hero_left h2 {
  opacity: 0.98;
  z-index: 999;

  color: #ffffff;
}

.service_hero_left p {
  opacity: 0.98;
  z-index: 999;
  text-align: justify;
  color: #ededed;
}

.service_hero_image {
  margin-top: 10rem;
}


  .solution .rows {
    width: 100%;
    height: auto;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .solution .rows::-webkit-scrollbar {
    overflow: hidden;
  }



  /* our portfolio sectoin */

  .our_portfolio_section .portfolio_left_section_offering{
  display: flex;
}

.our_portfolio_section .portfolio_heading{
  display: none;
}


/* products section */

.products{
  width: 100%;
  height: auto;
}
.products .container{
  width: 100%;
  height: auto;
}



.products .rows{
  width: 100%;
  height: auto;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
}

.products .rows::-webkit-scrollbar{
  overflow: hidden;
}
}


@media screen and (max-width: 576px) {

.swiper-wrapper {
  margin-left: 2% !important;
}

  .service_hero_section .container {
    width: 90vw !important;
    /* padding: 2rem; */
  }
.solution .rows::-webkit-scrollbar {
  overflow: hidden;
}
/* .service_hero_left {
  width: 80% !important;
  margin-top: 10rem;
} */
  .solution_heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
}

@media screen and (max-width: 492px) {
    .swiper-wrapper {
  /* margin-left: 2rem !important; */
}
}