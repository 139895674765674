html {
  font-size: 62.5%;
}

.portfolio_section1 {
  width: 100%;
  height: auto;
  /* background: red; */
  margin-top: 10rem;
}
.portfolio_section1 .container {
  width: 78%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.portfolio_section1 h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 4.3rem;
  line-height: 7rem;
  color: #000000;
}
.portfolio_section1 figure {
  width: 75%;
  /* height: 270px; */
}
.portfolio_section1 img {
  width: 100%;
  height: 100%;
  margin-left: 5rem;
}

/* mobile view */
.numbers_mobile_view {
  display: none;
}
/* mobile view */
/**********************portfolio section 2 ***************** */

.portfolio_achievenmetns {
  width: 100%;
  height: auto;
  /* background: grey; */
}

.portfolio_achievenmetns .container {
  width: 78%;
  margin: 0px auto;
}

.portfolio_achievenmetns .figure1,
.figure2 {
  width: 75%;
  /* height: 200px; */
}
.portfolio_achievenmetns .figure1 {
  margin-left: 6rem;
}
.portfolio_achievenmetns img {
  width: 100%;
  /* height: 300px; */
}

.portflio_achivenments_para p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.7rem;
  text-transform: capitalize;
  color: #7a7a7a;
  margin: 1rem 0px;
  word-spacing: 1px;
}
.portflio_achivenments_numbering h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.9rem;
  line-height: 2.8rem;
  color: #070a1d;
}

.portflio_achivenments_numbering .numbers {
  margin: 1.5rem 0px;
}

.portflio_achivenments_numbering .numbers h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.6rem;
  line-height: 2.9rem;
  /* identical to box height */

  color: #000000;
}

.portflio_achivenments_numbering .numbers p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  /* line-height: 23px; */

  color: #626262;
}

/**********************end portfolio section 2 ***************** */

/* *********************portfolio overview section *******************/

.portfolio_overview {
  width: 100%;
  height: auto;
  /* background: greenyellow; */
}

.portfolio_overview .container {
  width: 77%;
  margin: 3rem auto;
}

.porfolio_overview_img figure {
  width: 95%;
}
.porfolio_overview_img img {
  width: 100%;
}

.porfolio_overview_para h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  /* line-height: 30px; */
  /* identical to box height, or 180% */

  color: #5462ba;
}

.porfolio_overview_para h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 5rem;
  margin: 2rem 0rem;
  /* identical to box height, or 115% */

  letter-spacing: 0.04em;

  color: #5ea410;
}
.porfolio_overview_para p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  word-spacing: 1px;
  text-transform: capitalize;

  color: #7a7a7a;
}

.porfolio_overview_para .wend {
  color: #202e86;
}

.porfolio_overview_para .energy {
  color: #f6951d;
}
/* ***********************portfolio overview section end */

/* *************************portfolio second***************** */
/* start portfolio section */

.portfolio_portfolio_section {
  width: 100%;
  height: auto;
}
.portfolio_portfolio_section .container {
  width: 75%;
}

.portfolio_portfolio_heading h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3rem;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: capitalize;

  color: #5462ba;
}
.portfolio_portfolio_heading p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;

  color: #000000;
}

.portfolio_portfolio_img1 img {
  margin-right: 1rem !important;
  margin-top: 0;
}

.portfolio_portfolio_section .portfolio_portfolio_right h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 4.5rem;
  color: #000000;
}

.portfolio_portfolio_section .portfolio_portfolio_right h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 2.8rem;
  line-height: 4rem;
  /* or 136% */

  text-transform: capitalize;

  color: #3f3f3f;
}

.portfolio_portfolio_section .portfolio_portfolio_right p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem !important;
  line-height: 2.7rem !important;
  word-spacing: 0.1rem;
  text-align: justify;
  color: #707070;
  margin-top: 1rem;
}

.portfolio_portfolio_section .portfolio_portfolio_right button {
  margin-top: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
  text-transform: uppercase;
  color: #5ea410;
  transition: all 0.5s linear;
}


/* ****************************end of portfolio second */

/* *************************portfolio cards****************** */


.desktop_view_slider .mySwiper {
  width: 90% !important;
  margin-left: 3rem;
  margin: 0 auto !important;
  position: relative !important;
}

.portfolio_slide_cards {
  width: 100%;
  height: auto;
  /* background: #5462ba; */
}
.portfolio_slide_cards .container {
  width: 80%;
  margin: 3rem auto;
}

.heading_cards p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.3rem;
  color: #070a1d;
}

.portfolio_cards {
  /* width: 30rem; */
  padding: 2rem;
  border: 2px solid #5462ba;
}
.portfolio_slide_cards_desktop {
  width: 100%;
  margin: 1rem auto !important;
}
.portfolio_slide_cards_desktop .portfolio_cards {
  width: 100% !important;
  height: fit-content;
  /* padding: 2rem; */
  border: 2px solid #5462ba;
}

.portfolio_slide_cards_desktop .slick-next:before {
  position: absolute;
  top: -760%;
  right: 12rem;
  background: #202e86 !important;
  color: white !important;
  padding: 0.5rem;
  -webkit-font-smoothing: antialiased;
}

.portfolio_slide_cards_desktop .container_div {
  width: 80% !important;
  align-items: center;
  margin: 0 auto !important;
}
.portfolio_slide_cards_para p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-top: 1rem;
  color: #707070;
}

.portfolio_slide_cards .portfolio_mobile_cards {
  width: 34.5rem;
  height: auto;
}

/* for mobile view elements */

.portfolio_section1_mobile {
  display: none;
}

.mobile_overview_section {
  display: none;
}

.portfolio_slide_cards_desktop {
  display: block !important;
}

.portfolio_slide_cards_desktop {
  width: 100%;
  height: auto;
  /* background: #5462ba; */
}
.portfolio_slide_cards_desktop .container {
  width: 80%;
  margin: 3rem auto;
  padding: 0 !important;
}

.portfolio_slide_cards {
  display: none !important;
}




.potfolio_profile_img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background: rebeccapurple; */
}

.potfolio_profile_img img{
  width: 5rem !important;
  border-radius: 50%;
}

/* **************************portfolio cards */




/* *****************************media query***************************8 */

@media screen and (max-width: px) {
  .portfolio_section1 h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 4rem !important;
    line-height: 5rem !important;
    color: #000000;
  }

  .portfolio_overview .container {
  width: 85vw !important;
  margin: 3rem auto;
}

.portfolio_achievenmetns .container {
  width: 85vw !important;
  margin: 0px auto;
}

.portfolio_section1 .container {
  width: 85vw !important;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio_section1 .container {
  width: 85vw !important;
}

.portfolio_slide_cards_desktop .container {
  width: 85vw !important;
}

.portfolio_slide_cards_desktop .container_div {
  width: 85vw !important;
  align-items: center;
  margin: 0 auto !important;
}

}

@media screen and (max-width: 1024px) {
  .portfolio_section1 {
    width: 100%;
    height: auto;
    margin-top: 10rem;
  }
  /* .portfolio_section1 .container {
    width: 90vw !important;
    padding: 2rem !important;
    margin: 0rem auto;
  } */

.portfolio_section1 .container {
    width: 90%;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: center;}

  .portfolio_section1 h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 5rem !important;
    line-height: 7rem !important;
    color: #000000;
  }
  /* tablet view */

  .numbers_desktop_view {
    display: none;
  }

  .tablet_view{
    width: 100% !important;
  }

  /* mobile view */
  .numbers_mobile_view {
    display: block;
  }

  .hide_in_mobile_view {
    display: none;
  }
  .portfolio_section1_desktop {
    display: none;
  }

  .portfolio_section1_mobile {
    width: 100%;
    display: flex;
    gap: 3rem;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .portfolio_section1_mobile img {
    width: 100%;
    height: auto;
    margin-left: 0rem !important;
  }

  .portfolio_section1_mobile::-webkit-scrollbar {
    overflow: hidden;
  }

  /* mobile view */

  /* portfolio achievement section */
  .portfolio_achievenmetns {
    width: 100%;
    height: auto;
  }
  .portfolio_achievenmetns .container {
    width: 90vw;
    height: auto;
    /* padding: 0rem 2rem; */
  }

  /* portfolio_overview section */

  .portfolio_overview {
    width: 100%;
    height: auto;
    /* background: greenyellow; */
  }

  .porfolio_overview_para p {
    margin-top: 2rem !important;
  }
  .portfolio_overview .container {
    width: 90vw !important;
    margin: 3rem auto;
    padding: 2rem;
  }

  .porfolio_overview_img figure {
    width: 100%;
  }
  .porfolio_overview_img img {
    width: 100%;
  }

  .mobile_overview_section h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    /* line-height: 30px; */
    /* identical to box height, or 180% */

    color: #5462ba;
  }

  .mobile_overview_section h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: 7.5rem;
    letter-spacing: 0.04em;
    margin: 1rem 0rem;
    color: #5ea410;
  }

  .portfolio_overview_desktop_heading {
    display: none;
  }

  .portfolio_overview .mobile_overview_section {
    display: block;
  }

  .mobile_overview_section .wend {
    color: #202e86;
  }

  .mobile_overview_section .energy {
    color: #f6951d;
  }

  /* portofolio section  */

  .photo_gallary .container {
    width: 90% !important;
    padding: 2rem;
  }
  .photo_gallary_h1 {
    font-weight: 700 !important;
    font-size: 1.5rem;
    font-family: "poppins";
    font-style: normal;
    margin: 1rem 0rem;
  }
  .mobile_portfolio_gallary_section {
    width: 100%;
    /* padding: 0rem 4rem; */
  }
  .mobile_portfolio_gallary_section img {
    /* width: 50rem !important; */
    height: 50rem !important;
  }

  /* portfolio mobile_cards */

  .portfolio_slide_cards {
    width: 100%;
    height: auto;
  }
  .portfolio_slide_cards .container {
    width: 100%;
    padding: 0rem 3rem;
  }
  .portfolio_slide_cards .rows {
    width: 100%;
    display: flex !important;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .portfolio_slide_cards .rows::-webkit-scrollbar {
    overflow: hidden;
  }

  .portfolio_slide_cards_desktop {
    display: none !important;
  }

  .portfolio_slide_cards {
    display: block !important;
  }
}

/* 768px */
@media screen and (max-width: 768px) {
  .portfolio_section1 {
    width: 100%;
    height: auto;
    /* background: red; */
    margin-top: 10rem;
  }
  .portfolio_section1 .container {
    width: 90vw;
    padding: 2rem;
    margin: 0rem auto;
  }

  .numbers_desktop_view {
    display: none;
  }

  /* mobile view */
  .numbers_mobile_view {
    display: block;
  }

  .hide_in_mobile_view {
    display: none;
  }
  .portfolio_section1_desktop {
    display: none;
  }

  .portfolio_section1_mobile {
    width: 100%;
    display: flex;
    gap: 3rem;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .portfolio_section1_mobile img {
    width: 100%;
    height: auto;
    margin-left: 0rem !important;
  }

  .portfolio_section1_mobile::-webkit-scrollbar {
    overflow: hidden;
  }

  /* mobile view */

  /* portfolio achievement section */
  .portfolio_achievenmetns {
    width: 100%;
    height: auto;
  }
  .portfolio_achievenmetns .container {
    width: 90vw;
    height: auto;
    padding: 2rem;
  }

  /* portfolio_overview section */

  .portfolio_overview {
    width: 100%;
    height: auto;
    /* background: greenyellow; */
  }

  .portfolio_overview .container {
    width: 90vw !important;
    margin: 3rem auto;
    padding: 2rem;
  }

  .porfolio_overview_img figure {
    width: 100%;
  }
  .porfolio_overview_img img {
    width: 100%;
  }

  .mobile_overview_section h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    /* line-height: 30px; */
    /* identical to box height, or 180% */

    color: #5462ba;
  }

  .mobile_overview_section h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: 7.5rem;
    /* identical to box height, or 115% */

    letter-spacing: 0.04em;

    color: #5ea410;
  }

  .portfolio_overview_desktop_heading {
    display: none;
  }

  .portfolio_overview .mobile_overview_section {
    display: block;
  }

  .mobile_overview_section .wend {
    color: #202e86;
  }

  .mobile_overview_section .energy {
    color: #f6951d;
  }

  /* portofolio section  */

  .photo_gallary .container {
    width: 90%;
  }

  .mobile_portfolio_gallary_section {
    width: 100%;
  }
  .mobile_portfolio_gallary_section img {
    width: 100%;
    /* height: 40rem; */
  }

  /* portfolio mobile_cards */

  .portfolio_slide_cards {
    width: 100%;
    height: auto;
  }
  .portfolio_slide_cards .container {
    width: 100%;
    padding: 0rem 3rem;
  }
  .portfolio_slide_cards .rows {
    width: 100%;
    display: flex !important;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .portfolio_slide_cards .rows::-webkit-scrollbar {
    overflow: hidden;
  }
}

/* 576px */
